import { useState } from "react";
import axios from "axios";
import { requestHeader } from "../others/header";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const useCallAPI = (url) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const history = useHistory();

	const logout = (error) => {
		if (error?.response?.status == 401) {
			localStorage.removeItem("token");
			history.push("/logout");
		}
	};

	const reset = (state) => {
		setTimeout(() => {
			state.setError(null);
			state.setSuccess(null);
		}, 3000);
	};

	const post = async (requestData) => {
		setLoading(true);

		try {
			const response = await axios.post(url, requestData, requestHeader());
			setData(response.data);
			setSuccess(response.data.message);
		} catch (error) {
			setError(error);
			logout(error);
		} finally {
			setLoading(false);
			reset({ setError, setSuccess });
		}
	};

	const getList = async (query = "") => {
		setLoading(true);

		try {
			const response = await axios.get(`${url}?${query}`, requestHeader());
			setData(response.data);
			setSuccess(response.data.message);
		} catch (error) {
			console.log(error);
			setError(error);
			logout(error);
		} finally {
			setLoading(false);
			reset({ setError, setSuccess });
		}
	};

	const get = async (id, query) => {
		setLoading(true);

		try {
			const response = await axios.get(
				`${url}/${id}?${query}`,
				requestHeader()
			);
			setData(response.data);
			setSuccess(response.data.message);
		} catch (error) {
			setError(error);
			logout(error);
		} finally {
			setLoading(false);
			reset({ setError, setSuccess });
		}
	};
	const put = async (requestData, id) => {
		setLoading(true);

		try {
			const response = await axios.put(
				`${url}/${id}`,
				requestData,
				requestHeader()
			);
			setData(response.data);
			setSuccess(response.data.message);
		} catch (error) {
			setError(error);
			logout(error);
		} finally {
			setLoading(false);
			reset({ setError, setSuccess });
		}
	};

	//not allowed to use 'delete' as variable, that's why used deletee here.
	const deletee = async (id) => {
		setLoading(true);

		try {
			const response = await axios.delete(url + "/" + id, requestHeader());
			setData(response.data);
			setSuccess(response.data.message);
		} catch (error) {
			setError(error);
			logout(error);
		} finally {
			setLoading(false);
			reset({ setError, setSuccess });
			return;
		}
	};

	const patch = async (requestData, id) => {
		setLoading(true);

		try {
			const response = await axios.patch(
				`${url}/${id}`,
				requestData,
				requestHeader()
			);
			setData(response.data);
			setSuccess(response.data.message);
		} catch (error) {
			setError(error);
			logout(error);
		} finally {
			setLoading(false);
			reset({ setError, setSuccess });
		}
	};
	const DeleteCandidate = async (requestData) => {
		setLoading(true);

		try {
			const response = await axios.post(
				url + "/delete",
				requestData,
				requestHeader()
			);
			setData(response.data);
			setSuccess(response.data.message);
		} catch (error) {
			setError(error);
			logout(error);
		} finally {
			setLoading(false);
			reset({ setError, setSuccess });
			return;
		}
	};

	return {
		data,
		loading,
		success,
		error,
		post,
		get,
		put,
		deletee,
		patch,
		getList,
		DeleteCandidate,
	};
};
