import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
	AutoComplete,
	Button,
	Card,
	Col,
	DatePicker,
	Form,
	Input,
	Row,
	Select,
	Spin,
	Tag,
	Tooltip,
	Typography,
	Upload,
} from "antd";
import { setRole, setToken, setUser } from "../../../redux/actions/users";
import { showError } from "../../pages/utils/helpers";
import { CANDIDATE_RECRUITER_REGISTER, PARSE_CV } from "../../../ApiUrl";
import {
	monthsOfExpirance,
	yearsOfExpirance,
} from "../../pages/constant/constant";
import { requestHeader } from "../../../others/header";
import { logoutHandler } from "../../../others/logout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Text } = Typography;
const FormItem = Form.Item;

function AddCandidate(props) {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [msgSuccess, setMsgSuccess] = useState("");
	const [msgError, setmsgError] = useState("");
	const [selectedFileList, setSelectedFileList] = useState([]);
	const [selectedFile, setSelectedFile] = useState("");
	const [fileError, setFileError] = useState(false);
	const [parseLoading, setParseLoading] = useState(false);
	const [parseFileError, setParseFileError] = useState(false);
	const [data, setData] = useState(null);
	const [resumeParsed, setResumeParsed] = useState(false);
	const [bottomUploadDisabled, setBottomUploadDisabled] = useState(false);

	const [tags, setTags] = useState([]);

	const handleChange = (value) => {
		setTags(value);
	};
	const getBase64 = (file, callback) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			let result = reader.result;
			// For DOCX files, we need to adjust the MIME type
			if (
				file.type ===
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			) {
				result = result.replace(
					"data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
					"data:application/msword;base64,"
				);
			}
			callback(result);
		});
		reader.readAsDataURL(file);
	};
	const history = useHistory();
	// const propsUpload = {
	// 	onRemove: (file) => {
	// 		setSelectedFileList([]);
	// 		setSelectedFile("");
	// 		// const index = selectedFileList.indexOf(file);
	// 		// const newFileList = selectedFileList.slice();
	// 		// newFileList.splice(index, 1);
	// 		// setSelectedFileList(newFileList);
	// 	},
	// 	beforeUpload: (file) => {
	// 		if (file.size > 5000000) {
	// 			setError(true);
	// 			setmsgError("File should be less than 5 MB.");
	// 			// setDefaultState();
	// 			setDefaultStateForFile();
	// 			return false;
	// 		}

	// 		// if (
	// 		//   file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
	// 		//   file.type !== "application/msword" &&
	// 		//   file.type !== "application/pdf"
	// 		// ) {
	// 		const allowedTypes = [
	// 			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	// 			"application/msword",
	// 			"application/pdf",
	// 			"text/csv",
	// 		];
	// 		if (!allowedTypes.includes(file.type)) {
	// 			setError(true);
	// 			setmsgError("Not a supported file format.");
	// 			setDefaultStateForFile();
	// 			return false;
	// 		} else {
	// 			setSelectedFileList([file]);
	// 			getBase64(file, (result) => {
	// 				setSelectedFile(result);
	// 				setFileError(false);
	// 			});
	// 		}
	// 		// if (
	// 		// 	file.type !==
	// 		// 		"application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
	// 		// 	file.type !== "application/msword" &&
	// 		// 	file.type !== "application/pdf" &&
	// 		// 	file.type !== "text/csv"
	// 		// ) {
	// 		// 	setError(true);
	// 		// 	setmsgError("Not a supported file format.");
	// 		// 	// setDefaultState();
	// 		// 	setDefaultStateForFile();
	// 		// 	return false;
	// 		// } else {
	// 		// 	// setSelectedFileList([...selectedFileList, file]);
	// 		// 	// let idCardBase64 = ""; //vnd.openxmlformats-officedocument.wordprocessingml.document
	// 		// 	// //msword
	// 		// 	// getBase64(file, (result) => {
	// 		// 	// 	idCardBase64 = result.replace(
	// 		// 	// 		"vnd.openxmlformats-officedocument.wordprocessingml.document",
	// 		// 	// 		"msword"
	// 		// 	// 	);
	// 		// 	// 	setSelectedFile(idCardBase64);
	// 		// 	// 	const values = {
	// 		// 	// 		attachments: idCardBase64,
	// 		// 	// 		// profile_summary: userProfileDetails.profile_summary,
	// 		// 	// 	};
	// 		// 	// 	// postEditSummary(values);
	// 		// 	// });
	// 		// 	setSelectedFileList([file]);
	// 		// 	getBase64(file, (result) => {
	// 		// 		setSelectedFile(result);
	// 		// 		setFileError(false);
	// 		// 	});
	// 		// }
	// 		return false;
	// 	},
	// 	selectedFileList,
	// 	disabled: resumeParsed || bottomUploadDisabled,
	// };
	const onFinish = (values) => {
		if (!resumeParsed && !selectedFile && selectedFileList.length === 0) {
			setFileError(true);
			return;
		}

		const submitForm = (attachments) => {
			values.attachments = attachments;
			values.status = values.status ? values.status : "Active";

			axios
				.post(CANDIDATE_RECRUITER_REGISTER, values, requestHeader())
				.then((res) => {
					setLoading(false);
					if (!res.data.error) {
						setSuccess(true);
						setLoading(false);
						setError(false);
						setMsgSuccess(res.data.message);
						setDefaultState();
						setTimeout(() => {
							props.history.goBack();
						}, 3000);
					}
				})
				.catch((error) => {
					setSuccess(false);
					setLoading(false);
					setError(true);
					let errorMessage = "";
					{
						error.response.data &&
							Object.entries(error.response.data.errors).map(([key, value]) => {
								return (errorMessage += value + ", ");
							});
					}
					setmsgError(errorMessage);
					setTimeout(() => {
						setSuccess(false);
						setLoading(false);
						setError(false);
						setMsgSuccess("");
						setmsgError("");
					}, 3000);
					if (error?.response?.status == 401) {
						logoutHandler(history);
					}
				});
		};

		if (resumeParsed || selectedFile) {
			// Use the parsed resume file or already converted file
			submitForm(selectedFile);
		} else if (selectedFileList.length > 0) {
			// Convert the manually uploaded file to base64
			getBase64(selectedFileList[0], (result) => {
				submitForm(result);
			});
		} else {
			setFileError(true);
		}
	};
	// const onFinish = (values) => {
	// 	// if (resumeParsed) {
	// 	// 	values.attachments = "resume_parsed";
	// 	// } else {
	// 	// 	values.attachments = selectedFile;
	// 	// }
	// 	if (!selectedFile && selectedFileList.length === 0) {
	// 		setFileError(true);
	// 		return;
	// 	}

	// 	values.attachments = selectedFile;
	// 	values.status = values.status ? values.status : "Active";
	// 	let usertoken = JSON.parse(
	// 		JSON.parse(localStorage.getItem("persist:root")).users
	// 	).token;

	// 	axios
	// 		.post(CANDIDATE_RECRUITER_REGISTER, values, requestHeader())
	// 		.then((res) => {
	// 			setLoading(false);
	// 			if (!res.data.error) {
	// 				setSuccess(true);
	// 				setLoading(false);
	// 				setError(false);
	// 				setMsgSuccess(res.data.message);
	// 				setDefaultState();
	// 				setTimeout(() => {
	// 					props.history.goBack();
	// 				}, 3000);
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			setSuccess(false);
	// 			setLoading(false);
	// 			setError(true);
	// 			let errorMessage = "";
	// 			{
	// 				error.response.data &&
	// 					Object.entries(error.response.data.errors).map(([key, value]) => {
	// 						return (errorMessage += value + ", ");
	// 					});
	// 			}
	// 			setmsgError(errorMessage);
	// 			setTimeout(() => {
	// 				setSuccess(false);
	// 				setLoading(false);
	// 				setError(false);
	// 				setMsgSuccess("");
	// 				setmsgError("");
	// 			}, 3000);
	// 			if (error?.response?.status == 401) {
	// 				logoutHandler(history);
	// 			}
	// 		});
	// };

	const setDefaultState = () => {
		setTimeout(() => {
			setSuccess(false);
			setLoading(false);
			setError(false);
			setMsgSuccess("");
			setmsgError("");
			setClearState();
		}, 3000);
	};

	const setDefaultStateForFile = () => {
		setTimeout(() => {
			setSuccess(false);
			setLoading(false);
			setError(false);
			setMsgSuccess("");
			setmsgError("");
		}, 3000);
	};

	const setClearState = () => {
		form.current.setFieldsValue({
			first_name: "",
			middle_name: "",
			last_name: "",
			email: "",
			mobile: "",
			total_work_exp_year: "",
			total_work_exp_month: "",
			notes: "",
			attachments: "",
		});
		setSelectedFileList([]);
		setSelectedFile("");
	};

	const uploadCVToParse = {
		name: "file",
		multiple: false,
		accept:
			".pdf,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		beforeUpload: (file) => {
			setParseLoading(true);
			if (
				file.type !== "application/pdf" &&
				file.type !==
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			) {
				message.error("You can only upload PDF or DOCX files!");
				setParseLoading(false);
				return false;
			}
			const formData = new FormData();
			formData.append("file", file);

			axios
				.post(PARSE_CV, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				// .then(response => response.json())
				.then((result) => {
					// setData(result.data.data);
					form.current.setFieldsValue({
						first_name: result.data.data.first_name,
						middle_name: result.data.data.middle_name,
						last_name: result.data.data.last_name,
						email: result.data.data.email,
						mobile: result.data.data.mobile,
						total_work_exp_year: result.data.data.total_work_exp_year,
						total_work_exp_month: result.data.data.total_work_exp_month,
						notes: result.data.data.text,
					});
					setParseLoading(false);
					// message.success(`${file.name} file uploaded successfully.`);
					setResumeParsed(true);
					setBottomUploadDisabled(true);
					getBase64(file, (base64File) => {
						setSelectedFile(base64File);
						setSelectedFileList([file]);
					});
				})
				.catch((error) => {
					// console.error("Error uploading file", error);
					setParseLoading(false);
					setParseFileError(true);
					// message.error(`${file.name} file upload filed.`);
				});

			return false; // Prevent automatic upload by Ant Design
		},
		disabled: resumeParsed,
	};

	return (
		<>
			<Col span={24}>
				<FormItem
					label={
						<span>
							<span style={{ color: "#ff4d4f" }}>*</span> Upload Document (input
							candidate's data automatically! Only pdf and docx)
						</span>
					}
					name={"attachments"}
				>
					<Upload {...uploadCVToParse}>
						<Button disabled={resumeParsed} icon={<UploadOutlined />}>
							UPLOAD RESUME
						</Button>
					</Upload>

					{parseFileError && (
						<p style={{ color: "#ff4d4f" }}>Please upload a document</p>
					)}
				</FormItem>
				{parseLoading && <Spin className="loading" size="large" />}
			</Col>

			<Form ref={form} id="AddCandidate" layout="vertical" onFinish={onFinish}>
				{showError(success, msgSuccess, error, msgError)}
				<Card title={"Add Candidate"}>
					<Row gutter={24}>
						<Col span={8}>
							<FormItem
								label="First name"
								name={"first_name"}
								initialValue={data?.first_name}
								rules={[
									{
										required: true,
										message: "First name is required.",
									},
								]}
							>
								<Input placeholder="First name" />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem
								label="Middle name"
								initialValue={data?.middle_name}
								name={"middle_name"}
							>
								<Input placeholder="Middle name" />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem
								label="Last name"
								name={"last_name"}
								initialValue={data?.last_name}
								rules={[
									{
										required: true,
										message: "Last name is required.",
									},
								]}
							>
								<Input placeholder="Last name" />
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={12}>
							<FormItem
								label="Email"
								name={"email"}
								initialValue={data?.email}
								rules={[
									{
										type: "email",
										message: "The input is not valid E-mail!",
									},
									{
										required: true,
										message: "Email Address is required.",
									},
								]}
							>
								<Input placeholder="Email" />
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								label="Mobile"
								initialValue={data?.mobile}
								name={"mobile"}
								rules={[
									{
										required: true,
										message: "Mobile is required.",
									},
								]}
							>
								<Input placeholder="Mobile" />
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<FormItem label="Total Experience">
								<Row gutter={24}>
									<Col span={12}>
										<FormItem
											label="Years"
											name={"total_work_exp_year"}
											initialValue={data?.total_work_exp_year}
										>
											<Select>
												{yearsOfExpirance.map((user, index) => {
													return (
														<Option value={user.value} key={index}>
															{user.label + (index < 2 ? " Year" : " Years")}
														</Option>
													);
												})}
											</Select>
										</FormItem>
									</Col>
									<Col span={12}>
										<FormItem label="Months" name={"total_work_exp_month"}>
											<Select>
												{monthsOfExpirance.map((user, index) => {
													return (
														<Option value={user.value} key={index}>
															{user.label + (index < 2 ? " Month" : " Months")}
														</Option>
													);
												})}
											</Select>
										</FormItem>
									</Col>

									<Col span={12}>
										<Form.Item label="Input tags" name={"tags"}>
											<Select
												mode="tags"
												style={{ width: "100%" }}
												placeholder="input tags"
												onChange={handleChange}
												value={tags}
											>
												{tags.map((tag) => (
													<Option key={tag} value={tag}>
														<Tag color="blue">{tag}</Tag>
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem
								label="Notes"
								name={"notes"}
								initialValue={data?.text}

								// rules={[
								//   {
								//     required: true,
								//     message: "Notes is required.",
								//   },
								// ]}
							>
								<Input.TextArea rows={4} placeholder="Notes" />
							</FormItem>
						</Col>
						{/* <Col span={24}>
							<FormItem
								rules={[
									{
										validator: (_, value) => {
											if (
												resumeParsed ||
												selectedFile ||
												(selectedFileList && selectedFileList.length > 0)
											) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error("Please upload a document")
											);
										},
									},
								]}
								label={
									<span>
										<span style={{ color: "#ff4d4f" }}></span> Upload Document
										(only doc, docx, pdf, csv)
									</span>
								}
								name={"attachments"}
							>
								<Upload
									{...propsUpload}
									accept=".pdf, .doc, .docx , .csv"
									fileList={selectedFileList}
									onChange={({ fileList }) => setSelectedFileList(fileList)}
								>
									<Button disabled={bottomUploadDisabled || resumeParsed}>
										UPLOAD RESUME
									</Button>
								</Upload>
								<Text type="secondary">
									Supported Formats: doc, docx, pdf, csv, upto 5 MB
								</Text>
							</FormItem>
						</Col> */}
					</Row>
				</Card>
				<br />
				<Card>
					<Row gutter={24}>
						<Col span={24}>
							{loading ? (
								<Button type="primary" size="large" loading>
									Saving . .
								</Button>
							) : (
								<Button type="primary" size="large" htmlType="submit">
									Submit
								</Button>
							)}
						</Col>
					</Row>
				</Card>
			</Form>
		</>
	);
}

const mapDispatchToProps = (dispatch) => ({
	setRole: (data) => {
		dispatch(setRole(data));
	},
	setUser: (data) => {
		dispatch(setUser(data));
	},
	setToken: (data) => {
		dispatch(setToken(data));
	},
});

export default connect(null, mapDispatchToProps)(AddCandidate);
