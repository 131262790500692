import React from 'react'
const NotFound = () => {
    return (
        <div className='d-flex justify-content-center mt-5'>
            <h1 className='h1'>404 Not Found</h1>
        </div>
    );
};

export default NotFound;
