export const handleSearch = (e, timeoutRef, setSearch) => {
  const value = e.target.value;

  // Clear the existing timeout
  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
  }

  // Set a new timeout
  timeoutRef.current = setTimeout(() => {
    setSearch(value);
  }, 2000);
};
