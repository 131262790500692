import { combineReducers } from "redux";

import userReducer from "./users";
import commonReducer from "./common";
import searchFormReducer from "./searchFormSlice";
import candidatesSlice from "./candidatesSlice";

const mainReducer = combineReducers({
	users: userReducer,
	common: commonReducer,
	searchForm: searchFormReducer,
	candidates: candidatesSlice
});

export default mainReducer;
