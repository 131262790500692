import { configureStore } from "@reduxjs/toolkit";
import mainReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
	key: "root",
	storage,
	whiteList: ["users", "common", "searchForm"],
};

const persistedReducer = persistReducer(persistConfig, mainReducer);

// export const store = createStore(persistedReducer);
export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
export const persistor = persistStore(store);

// import { configureStore } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// // import candidateSlice from './slices/candidateSlice';
// import candidateSlice from './reducers/candidatesSlice'
// import userSlice from './reducers/userSlice';
// import commonSlice from './reducers/commonSlice';
// import searchFormSlice from './reducers/searchFormSlice';

// const persistConfig = {
// 	key: 'root',
// 	storage,
// 	whitelist: ['users', 'common', 'searchForm', 'candidates'],
// };

// const persistedReducer = persistReducer(persistConfig, combineReducers({
// 	users: userSlice,
// 	common: commonSlice,
// 	searchForm: searchFormSlice,
// 	candidates: candidateSlice,
// }));

// export const store = configureStore({
// 	reducer: persistedReducer,
// 	middleware: (getDefaultMiddleware) =>
// 		getDefaultMiddleware({
// 			serializableCheck: false,
// 		}),
// });

// export const persistor = persistStore(store);
