import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";

/**
 *  show success or error message
 */
export const CustomSnackbar = ({success, error}) => {
  return (
    <>
      <Snackbar open={success} autoHideDuration={3000}>
        <Alert severity="success" variant="filled">
          {success}
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={3000}>
        <Alert severity="error" variant="filled">
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};
