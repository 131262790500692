import { SET_SEARCH_FORM } from "../constants/searchForm";

const initialState = {
	keyword: "",
	jobTitles: "",
	willingnessToRelocate: false,
	resumePostedFrom: "",
	resumePostedTo: "",
	yearsOfExperience: [],
	isSearchByZipCodeRadius: false,
	searchZipCodeRadius: "",
	zipCode: "",
};

const searchFormReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SEARCH_FORM:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

export default searchFormReducer;
