export const logoutHandler = (history) => {
  localStorage.removeItem("token");
  // console.log("clicked on log out")
  // const toggle = document.getElementById("kt_quick_user_toggle");
  // if (toggle) {
  //   toggle.click();
  // }
  // Remove the token from local storage

  history.push("/logout");
};
// const history = useHistory();
