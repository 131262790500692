export const handleCopy = (e) => {
    e.preventDefault();
};

export const handleCut = (e) => {
    e.preventDefault();
};

export const handlePaste = (e) => {
    e.preventDefault();
};