import React from "react";
import { Col, Row } from "antd";
import { Redirect, Route, Switch } from "react-router-dom";
import SmtpSettings from "./smtpSettings";
import MessageQuickLinks from "./MessageQuickLinks";
import Compose from "../messages/compose";
import Inbox from "../messages/inbox";
import Sent from "../messages/sent";
import Messages from "../messages/Messages";
import MailDetail from "../messages/mailDetail";
import EmailTempalte from "../messages/EmailTemplates";
import { useSelector } from "react-redux";

function MessagesComponent() {
  const users = useSelector(({ users }) => users);
  const role = users.role;  
  const createNewClientPath = role === "admin" ? 'admin' : role === "recruitmentmanager" ? 'recruitment-manager' : role === "bdmmanager" ? 'bdm-manager' : role;
  return (
    <div>
      <Row gutter={24}>
        <Col span={6}>
          <MessageQuickLinks />
        </Col>
        <Col span={18}>
          <Switch>
            <Redirect
              from={`/${createNewClientPath}/messages`}
              exact={true}
              to={`/${createNewClientPath}/messages/smtpSettings`}
            />
            <Route
              path={`/${createNewClientPath}/messages/smtpSettings`}
              component={() => <SmtpSettings />}
            />
            <Route
              path={`/${createNewClientPath}/messages/Compose`}
              component={() => <Compose />}
            />
            <Route
              path={`/${createNewClientPath}/messages/Inbox`}
              component={() => <Inbox />}
            />
            <Route path={`/${createNewClientPath}/messages/Sent`} component={() => <Sent />} />
            <Route
              path={`/${createNewClientPath}/messages/MailDetail`}
              component={() => <MailDetail />}
            />
            <Route
              path={`/${createNewClientPath}/messages/EmailTempalte`}
              component={() => <EmailTempalte />}
            />
            <Route
              path={`/${createNewClientPath}/messages/messages`}
              component={() => <Messages />}
            />
          </Switch>
        </Col>
      </Row>
    </div>
  );
}

export default MessagesComponent;
