import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_theme_parts/layout";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import OpeningList from "./modules/Openings/OpeningList";

import UserList from "./modules/Auth/pages/admin/UserList/UserList";
import AddUser from "./modules/Auth/pages/admin/UserList/createUser";
import EditUser from "./modules/Auth/pages/admin/UserList/EditUser";
import UserProfilepage from "./modules/ProfileUser/Profile";
import OpeningsDetail from "./modules/Openings/OpeningsDetail";
import CandidateList from "./modules/Auth/pages/admin/candidate/CandidateList";
import ContactsListAdmin from "./modules/Auth/pages/admin/ContactsList";

import AdminMessages from "./modules/adminMessages/Messages";
import SubscriptionAdmin from "./modules/Subscription/Subscription";
import AddEditSubscription from "./modules/Subscription/AddSubscription";
import AdminReports from "./modules/Auth/pages/admin/reports";
import UserWiseCandidateSubmissionList from "./modules/Openings/UserWiseCandidateSubmissionList";
import ClientsList from "./modules/clients/ClientsList";
import ClientDetail from "./modules/clients/ClientDetail";
import AddCandidate from "./modules/AddCandidate/AddCandidate";
import AiRecruiter from "./modules/AiRecruiter/AiRecruiter";
import AddOpening from "./pages/company/AddOpening/AddOpening";
import AddOpeningBdm from "./pages/bdm/company/AddOpening";

import OpeningCompanyList from "./pages/company/OpeningList";
import OpeningsDetailCompany from "./pages/company/OpeningsDetail";
import CompanyProfile from "./pages/company/CompanyProfile";
import CompanyCandidateList from "./pages/company/CandidateList";
import CandidateProfileDetail from "./pages/company/CandidateProfileDetail";
import Subscription from "./pages/company/Subscription";
import Messages from "./pages/company/Messages";
import ContactsList from "./pages/company/contacts/ContactsList";
import AddContact from "./pages/company/contacts/AddEditContact";
import ContactActivityLogList from "./pages/company/ContactsActivityLog";
import OpeningWiseCandidateList from "./pages/company/OpeningWiseCandidateList";
import CompanyReports from "./pages/company/reports";

import OpeningsDetailBDM from "./pages/bdm/OpeningsDetail";
import AssignJobs from "./pages/bdm/AssignedJobs";
import OpeningCompanyListBDM from "./pages/bdm/company/OpeningCompanyListBDM";
import ContactsListBDM from "./pages/bdm/company/ContactsList";
import AddContactBDM from "./pages/bdm/company/AddEditContact";
import WorkJobsListByFreelancer from "./pages/bdm/WorkJobsListByFreelancer";
import BDMReports from "./pages/bdm/reports";

import FreelancerSearchJobs from "./pages/freelancerecruiter/SearchJobs";
import FreelancerSearchJobDetail from "./pages/freelancerecruiter/SearchJobDetail";

import OpeningsDetailFreelancerecruite from "./pages/freelancerecruiter/OpeningsDetail";
import FreelancerReports from "./pages/freelancerecruiter/reports";
import AssignJobsFreelancer from "./pages/freelancerecruiter/assignedJobs";
import MessagesFreelancer from "./pages/freelancerecruiter/Messages";

import AssignJobsRecruiter from "./pages/recruiter/assignedJobs";
import OpeningsDetailRecruiter from "./pages/recruiter/OpeningsDetail";

import CandidateApplyJobs from "./pages/recruiter/CandidateApplyJobList";

import RecruiterReports from "./pages/recruiter/reports";

import CandidateProfile from "./pages/Candidate/Profile";
import SearchJobs from "./pages/Candidate/SearchJobs";
import MyJobApplication from "./pages/Candidate/MyJobApplication";
import JobDetail from "./pages/Candidate/JobDetail";
import SearchJobDetail from "./pages/Candidate/SearchJobDetail";
import SavedJobs from "./pages/Candidate/SavedJobs";
import CandidateMessages from "./pages/Candidate/Messages";

import { useSelector } from "react-redux";
import { Logout } from "./modules/Auth";
import createCompany from "./pages/company/createCompany";
import editCompany from "./pages/company/editCompany";
import MonsterSearch from "./modules/monster";
import NewAutoSource from "./modules/autoSourcing/NewAutoSource";
import AutoSourcingList from "./modules/autoSourcing/AutoSourcingList";
import SearchList from "./modules/monster/SearchList";
import SearchDetail from "./modules/monster/SearchDetail";
import { JobOpenings, AutoSourceCandidate } from "./pages/bdm/AutoSource";
import EditCandidate from "./modules/AddCandidate/EditCandidate";
import CandidateDynamicList from "./pages/Candidate/CandidateDynamicList2";
import { OpeningList_v2 } from "./modules/Openings/OpeningList_V2";
import AssignedJobs_v2 from "./pages/recruiter/assignedJobs_v2";

export default function BasePage() {
	const role = useSelector(({ users }) => users.role);
	localStorage.removeItem("loggedOutUserRole");
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{/* Redirect from root URL to /dashboard. */
				role === "candidate" ? (
					<Redirect exact from="/" to="/candidate/profile" />
				) : (
					<Redirect exact from="/" to="/dashboard" />
				)}
				{role === "admin" ? (
					<div>
						<ContentRoute path="/dashboard" component={DashboardPage} />
						<ContentRoute path="/my-page" component={MyPage} />
						<ContentRoute path="/current-openings" component={OpeningList_v2} />
						<ContentRoute path="/user/list/:status" component={UserList} />
						<ContentRoute
							path="/admin/opening-detail"
							component={OpeningsDetail}
						/>

						<Route path="/admin/add-opening" component={AddOpeningBdm} />
						<Route path="/admin/AIrecruiter" component={AiRecruiter} />
						<ContentRoute path="/user/add-user" component={AddUser} />
						<ContentRoute path="/user/edit-user" component={EditUser} />
						<Route
							path="/admin/candidateProfileDetail"
							component={CandidateProfileDetail}
						/>
						<ContentRoute
							path="/user/candidateList"
							component={CandidateList}
						/>
						<Route path="/admin/addCandidate" component={AddCandidate} />
						<Route path="/admin/edit-candidate" component={EditCandidate} />
						<ContentRoute
							path="/admin/user-profile"
							component={UserProfilepage}
						/>
						<Route path="/admin/ClientsList" component={ClientsList} />
						<Route path="/admin/ClientDetail" component={ClientDetail} />

						<Route path="/admin/add-client" component={createCompany}></Route>
						<Route path="/admin/edit-client" component={editCompany}></Route>

						<Route path="/admin/contacts" component={ContactsListAdmin}></Route>
						<Route path="/admin/addContact" component={AddContactBDM}></Route>

						<Route path="/admin/messages" component={AdminMessages} />
						<Route path="/admin/Subscription" component={SubscriptionAdmin} />
						<Route
							path="/admin/AddEditSubscription"
							component={AddEditSubscription}
						/>
						{/* <Route
              path="/admin/autosource-candidate"
              render={(props) => (
                <AutoSourceCandidate {...props} type="admin" />
              )}
            /> */}
						<Route path="/admin/reports" component={AdminReports} />
						<Route path="/admin/monsterSearch" component={MonsterSearch} />
						{/* <Route path="/admin/autosource" component={OpeningList} /> */}

						<Route path="/admin/SearchList" component={SearchList} />
						<Route path="/admin/SearchDetail" component={SearchDetail} />
						<Route path="/admin/autosource-new" component={NewAutoSource} />

						<Route
							path="/admin/autosource"
							render={(props) => <JobOpenings {...props} type="admin" />}
						/>

						<Route
							path="/admin/autosource-candidate"
							render={(props) => (
								<CandidateDynamicList {...props} type="admin" />
							)}
						/>

						<ContentRoute path="/logout" component={Logout} />
					</div>
				) : (
					""
				)}
				{role === "bdmmanager" ? (
					<div>
						<ContentRoute path="/dashboard" component={DashboardPage} />
						<ContentRoute path="/my-page" component={MyPage} />
						<ContentRoute path="/current-openings" component={OpeningList_v2} />
						<ContentRoute path="/user/list/:status" component={UserList} />
						<ContentRoute
							path="/bdm-manager/opening-detail"
							component={OpeningsDetail}
						/>

						<Route path="/bdm-manager/add-opening" component={AddOpeningBdm} />
						<Route path="/bdm-manager/AIrecruiter" component={AiRecruiter} />
						<ContentRoute path="/user/add-user" component={AddUser} />
						<ContentRoute path="/user/edit-user" component={EditUser} />
						<Route
							path="/bdm-manager/candidateProfileDetail"
							component={CandidateProfileDetail}
						/>
						<ContentRoute
							path="/user/candidateList"
							component={CandidateList}
						/>
						<Route path="/bdm-manager/addCandidate" component={AddCandidate} />
						<Route
							path="/bdm-manager/edit-candidate"
							component={EditCandidate}
						/>
						<ContentRoute
							path="/bdm-manager/user-profile"
							component={UserProfilepage}
						/>
						<Route path="/bdm-manager/ClientsList" component={ClientsList} />
						<Route path="/bdm-manager/ClientDetail" component={ClientDetail} />

						<Route
							path="/bdm-manager/add-client"
							component={createCompany}
						></Route>
						<Route
							path="/bdm-manager/edit-client"
							component={editCompany}
						></Route>

						<Route
							path="/bdm-manager/contacts"
							component={ContactsListAdmin}
						></Route>
						<Route
							path="/bdm-manager/addContact"
							component={AddContactBDM}
						></Route>

						<Route path="/bdm-manager/messages" component={AdminMessages} />
						<Route
							path="/bdm-manager/Subscription"
							component={SubscriptionAdmin}
						/>
						<Route
							path="/bdm-manager/AddEditSubscription"
							component={AddEditSubscription}
						/>
						{/* <Route
              path="/admin/autosource-candidate"
              render={(props) => (
                <AutoSourceCandidate {...props} type="admin" />
              )}
            /> */}
						<Route path="/bdm-manager/reports" component={AdminReports} />
						<Route path="/admin/monsterSearch" component={MonsterSearch} />
						{/* <Route path="/admin/autosource" component={OpeningList} /> */}

						<Route path="/admin/SearchList" component={SearchList} />
						<Route path="/admin/SearchDetail" component={SearchDetail} />
						<Route
							path="/bdm-manager/autosource-new"
							component={NewAutoSource}
						/>

						<Route
							path="/admin/autosource"
							render={(props) => <JobOpenings {...props} type="admin" />}
						/>

						<Route
							path="/admin/autosource-candidate"
							render={(props) => (
								<CandidateDynamicList {...props} type="admin" />
							)}
						/>

						<ContentRoute path="/logout" component={Logout} />
					</div>
				) : (
					""
				)}
				{role === "recruitmentmanager" ? (
					<div>
						<ContentRoute path="/dashboard" component={DashboardPage} />
						<ContentRoute path="/my-page" component={MyPage} />
						<ContentRoute path="/current-openings" component={OpeningList_v2} />
						<ContentRoute path="/user/list/:status" component={UserList} />
						<ContentRoute
							path="/recruitment-manager/opening-detail"
							component={OpeningsDetail}
						/>

						<Route
							path="/recruitment-manager/add-opening"
							component={AddOpeningBdm}
						/>
						<Route
							path="/recruitment-manager/AIrecruiter"
							component={AiRecruiter}
						/>
						<ContentRoute path="/user/add-user" component={AddUser} />
						<ContentRoute path="/user/edit-user" component={EditUser} />
						<Route
							path="/recruitment-manager/candidateProfileDetail"
							component={CandidateProfileDetail}
						/>
						<ContentRoute
							path="/user/candidateList"
							component={CandidateList}
						/>
						<Route
							path="/recruitment-manager/addCandidate"
							component={AddCandidate}
						/>
						<Route
							path="/recruitment-manager/edit-candidate"
							component={EditCandidate}
						/>
						<ContentRoute
							path="/recruitment-manager/user-profile"
							component={UserProfilepage}
						/>
						<Route
							path="/recruitment-manager/ClientsList"
							component={ClientsList}
						/>
						<Route
							path="/recruitment-manager/ClientDetail"
							component={ClientDetail}
						/>

						<Route
							path="/recruitment-manager/add-client"
							component={createCompany}
						></Route>
						<Route
							path="/recruitment-manager/edit-client"
							component={editCompany}
						></Route>

						<Route
							path="/recruitment-manager/contacts"
							component={ContactsListAdmin}
						></Route>
						<Route
							path="/recruitment-manager/addContact"
							component={AddContactBDM}
						></Route>

						<Route
							path="/recruitment-manager/messages"
							component={AdminMessages}
						/>
						<Route
							path="/recruitment-manager/Subscription"
							component={SubscriptionAdmin}
						/>
						<Route
							path="/recruitment-manager/AddEditSubscription"
							component={AddEditSubscription}
						/>
						{/* <Route
              path="/admin/autosource-candidate"
              render={(props) => (
                <AutoSourceCandidate {...props} type="admin" />
              )}
            /> */}
						<Route
							path="/recruitment-manager/reports"
							component={AdminReports}
						/>
						<Route path="/admin/monsterSearch" component={MonsterSearch} />
						{/* <Route path="/admin/autosource" component={OpeningList} /> */}

						<Route path="/admin/SearchList" component={SearchList} />
						<Route path="/admin/SearchDetail" component={SearchDetail} />
						<Route
							path="/recruitment-manager/autosource-new"
							component={NewAutoSource}
						/>

						<Route
							path="/admin/autosource"
							render={(props) => <JobOpenings {...props} type="admin" />}
						/>

						<Route
							path="/admin/autosource-candidate"
							render={(props) => (
								<CandidateDynamicList {...props} type="admin" />
							)}
						/>

						<ContentRoute path="/logout" component={Logout} />
					</div>
				) : (
					""
				)}
				{role === "operations" ? (
					<div>
						<ContentRoute path="/dashboard" component={DashboardPage} />
						<ContentRoute path="/my-page" component={MyPage} />
						<ContentRoute path="/current-openings" component={OpeningList_v2} />
						<ContentRoute path="/user/list/:status" component={UserList} />
						<ContentRoute
							path="/operations/opening-detail"
							component={OpeningsDetail}
						/>

						<Route path="/operations/add-opening" component={AddOpeningBdm} />
						<Route path="/operations/AIrecruiter" component={AiRecruiter} />
						<ContentRoute path="/user/add-user" component={AddUser} />
						<ContentRoute path="/user/edit-user" component={EditUser} />
						<Route
							path="/operations/candidateProfileDetail"
							component={CandidateProfileDetail}
						/>
						<ContentRoute
							path="/user/candidateList"
							component={CandidateList}
						/>
						<Route path="/operations/addCandidate" component={AddCandidate} />
						<Route
							path="/operations/edit-candidate"
							component={EditCandidate}
						/>
						<ContentRoute
							path="/operations/user-profile"
							component={UserProfilepage}
						/>
						<Route path="/operations/ClientsList" component={ClientsList} />
						<Route path="/operations/ClientDetail" component={ClientDetail} />

						<Route
							path="/operations/add-client"
							component={createCompany}
						></Route>
						<Route
							path="/operations/edit-client"
							component={editCompany}
						></Route>

						<Route
							path="/operations/contacts"
							component={ContactsListAdmin}
						></Route>
						<Route
							path="/operations/addContact"
							component={AddContactBDM}
						></Route>

						<Route path="/operations/messages" component={AdminMessages} />
						<Route
							path="/operations/Subscription"
							component={SubscriptionAdmin}
						/>
						<Route
							path="/operations/AddEditSubscription"
							component={AddEditSubscription}
						/>
						{/* <Route
              path="/admin/autosource-candidate"
              render={(props) => (
                <AutoSourceCandidate {...props} type="admin" />
              )}
            /> */}
						<Route path="/operations/reports" component={AdminReports} />
						<Route path="/admin/monsterSearch" component={MonsterSearch} />
						{/* <Route path="/admin/autosource" component={OpeningList} /> */}

						<Route path="/admin/SearchList" component={SearchList} />
						<Route path="/admin/SearchDetail" component={SearchDetail} />
						<Route
							path="/operations/autosource-new"
							component={NewAutoSource}
						/>

						<Route
							path="/admin/autosource"
							render={(props) => <JobOpenings {...props} type="admin" />}
						/>

						<Route
							path="/admin/autosource-candidate"
							render={(props) => (
								<CandidateDynamicList {...props} type="admin" />
							)}
						/>

						<ContentRoute path="/logout" component={Logout} />
					</div>
				) : (
					""
				)}
				{role === "company" ? (
					<div>
						<Route path="/dashboard" component={DashboardPage} />
						<Route
							path="/company/job-openings"
							component={OpeningCompanyList}
						/>
						<Route path="/company/add-opening" component={AddOpening} />
						<Route
							path="/company/opening-detail"
							component={OpeningsDetailCompany}
						/>
						<Route path="/company/user-profile" component={CompanyProfile} />
						<Route
							path="/company/candidates"
							component={CompanyCandidateList}
						/>
						<Route path="/company/subscription" component={Subscription} />
						<Route path="/company/messages" component={Messages} />
						<Route
							path="/company/candidateProfileDetail"
							component={CandidateProfileDetail}
						/>
						<Route path="/company/Contacts" component={ContactsList} />
						<Route path="/company/AddContact" component={AddContact} />
						<Route
							path="/company/contactActivityLogList"
							component={ContactActivityLogList}
						/>
						<Route
							path="/company/OpeningWiseCandidates/:openingid/:companyid/:status"
							component={OpeningWiseCandidateList}
						/>
						<Route
							path="/company/autosource-candidate"
							render={(props) => (
								<AutoSourceCandidate {...props} type="company" />
							)}
						/>
						{/*<Route path="/company/agreements" component={AgreementListing}/>*/}
						<Route path="/company/reports" component={CompanyReports} />
						<ContentRoute path="/logout" component={Logout} />
					</div>
				) : (
					""
				)}
				{role === "bdm" ? (
					<div>
						<Route path="/dashboard" component={DashboardPage} />
						<Route path="/bdm/ClientsList" component={ClientsList} />
						<Route path="/bdm/assign-jobs" component={AssignedJobs_v2} />
						<Route path="/bdm/add-opening" component={AddOpeningBdm} />
						<Route path="/bdm/opening-detail" component={OpeningsDetailBDM} />
						<Route path="/bdm/user-profile" component={UserProfilepage} />
						<Route path="/bdm/job-openings" component={OpeningCompanyListBDM} />
						<Route
							path="/bdm/candidateProfileDetail"
							component={CandidateProfileDetail}
						/>
						<Route path="/bdm/AIrecruiter" component={AiRecruiter} />
						<Route path="/bdm/ClientDetail" component={ClientDetail} />
						<Route
							path="/bdm/OpeningWiseCandidates/:openingid/:companyid/:status"
							component={OpeningWiseCandidateList}
						/>
						<Route path="/bdm/messages" component={AdminMessages} />
						<Route
							path="/bdm/workJobsList"
							component={WorkJobsListByFreelancer}
						/>
						<Route path="/bdm/addCandidate" component={AddCandidate} />
						<Route path="/bdm/edit-candidate" component={EditCandidate} />
						{/* <Route path="/bdm/CandidateList" component={CandidateList} /> */}

						<Route
							path="/bdm/CandidateList"
							render={(props) => <CandidateList {...props} role="bdm" />}
						/>

						{/*<Route path="/company/agreements" component={AgreementListing}/>*/}
						<Route path="/bdm/reports" component={BDMReports} />
						<Route
							path="/bdm/userWiseCandidateSubmission/:status"
							component={UserWiseCandidateSubmissionList}
						/>
						<Route path="/bdm/add-client" component={createCompany}></Route>
						<Route path="/bdm/edit-client" component={editCompany}></Route>
						<Route path="/bdm/contacts" component={ContactsListBDM}></Route>
						<Route path="/bdm/addContact" component={AddContactBDM}></Route>
						{/* <Route path="/bdm/autosource" component={NewAutoSource} /> */}
						<Route path="/bdm/autosource" component={JobOpenings} />

						<Route
							path="/bdm/autosource-candidate"
							render={(props) => <CandidateDynamicList {...props} type="bdm" />}
						/>
						{/* <Route path="/bdm/monsterSearch" component={MonsterSearch} /> */}

						<Route
							path="/bdm/monsterSearch"
							render={(props) => <MonsterSearch {...props} type="bdm" />}
						/>

						<Route
							path="/bdm/SearchList"
							render={(props) => <SearchList {...props} type="bdm" />}
						/>
						<Route path="/bdm/SearchDetail" component={SearchDetail} />

						<ContentRoute path="/logout" component={Logout} />
					</div>
				) : (
					""
				)}
				{role === "recruiter" ? (
					<div>
						<Route path="/dashboard" component={DashboardPage} />
						<Route
							path="/recruiter/assign-jobs"
							// component={AssignJobsRecruiter}
							component={AssignedJobs_v2}
						/>
						<Route
							path="/recruiter/CandidateList"
							render={(props) => <CandidateList {...props} role="recruiter" />}
						/>
						<Route
							path="/recruiter/opening-detail"
							component={OpeningsDetailRecruiter}
						/>
						<Route path="/recruiter/user-profile" component={UserProfilepage} />
						<Route
							path="/recruiter/candidateProfileDetail"
							component={CandidateProfileDetail}
						/>
						<Route path="/recruiter/AIrecruiter" component={AiRecruiter} />
						<Route
							path="/recruiter/OpeningWiseCandidates/:openingid/:companyid/:status"
							component={OpeningWiseCandidateList}
						/>
						<Route path="/recruiter/addCandidate" component={AddCandidate} />
						<Route path="/recruiter/edit-candidate" component={EditCandidate} />
						<Route
							path="/recruiter/candidateApplyJobs"
							component={CandidateApplyJobs}
						/>
						<Route path="/recruiter/messages" component={AdminMessages} />
						<Route path="/recruiter/reports" component={RecruiterReports} />
						{/* <Route path="/recruiter/autosource" component={JobOpenings} /> */}

						<Route
							path="/recruiter/autosource"
							render={(props) => <JobOpenings {...props} type="recruiter" />}
						/>

						<Route
							path="/recruiter/autosource-candidate"
							render={(props) => (
								<CandidateDynamicList {...props} type="recruiter" />
							)}
						/>

						<Route
							path="/recruiter/monsterSearch"
							render={(props) => <MonsterSearch {...props} type="recruiter" />}
						/>

						<Route
							path="/recruiter/SearchList"
							render={(props) => <SearchList {...props} type="recruiter" />}
						/>
						<Route path="/recruiter/SearchDetail" component={SearchDetail} />

						<Route
							path="/recruiter/userWiseCandidateSubmission/:status"
							component={UserWiseCandidateSubmissionList}
						/>
						{/* <Route path="/recruiter/autosource" component={NewAutoSource} /> */}
						{/* <Route path="/recruiter/autosource" component={JobOpenings} /> */}
						<ContentRoute path="/logout" component={Logout} />
					</div>
				) : (
					""
				)}
				{role === "freelancerecruiter" ? (
					<div>
						<Route path="/dashboard" component={DashboardPage} />
						<Route
							path="/freelancerecruiter/all-jobs"
							component={FreelancerSearchJobs}
						/>
						<Route
							path="/freelancerecruiter/assign-jobs"
							// component={AssignJobsFreelancer}
							component={AssignedJobs_v2}
						/>
						<Route
							path="/freelancerecruiter/SearchJobDetail"
							component={FreelancerSearchJobDetail}
						/>
						<Route
							path="/freelancerecruiter/addCandidate"
							component={AddCandidate}
						/>
						<Route
							path="/freelancerecruiter/AIrecruiter"
							component={AiRecruiter}
						/>
						<Route
							path="/freelancerecruiter/opening-detail"
							component={OpeningsDetailFreelancerecruite}
						/>
						<Route
							path="/freelancerecruiter/OpeningWiseCandidates/:openingid/:companyid/:status"
							component={OpeningWiseCandidateList}
						/>
						<Route
							path="/freelancerecruiter/user-profile"
							component={UserProfilepage}
						/>
						<Route
							path="/freelancerecruiter/candidateProfileDetail"
							component={CandidateProfileDetail}
						/>
						<Route
							path="/freelancerecruiter/edit-candidate"
							component={EditCandidate}
						/>
						<Route
							path="/freelancerecruiter/autosource"
							render={(props) => (
								<JobOpenings {...props} type="freelancerecruiter" />
							)}
						/>

						<Route
							path="/freelancerecruiter/autosource-candidate"
							render={(props) => (
								<CandidateDynamicList {...props} type="freelancerecruiter" />
							)}
						/>

						<Route
							path="/freelancerecruiter/messages"
							component={MessagesFreelancer}
						/>
						<Route
							path="/freelancerecruiter/reports"
							component={FreelancerReports}
						/>
						<Route
							path="/freelancerecruiter/userWiseCandidateSubmission/:status"
							component={UserWiseCandidateSubmissionList}
						/>
						{/* <Route
              path="/freelancerecruiter/autosource"
              component={NewAutoSource}
            /> */}

						<Route
							path="/freelancerecruiter/monsterSearch"
							render={(props) => (
								<MonsterSearch {...props} type="freelancerecruiter" />
							)}
						/>

						<Route
							path="/freelancerecruiter/SearchList"
							render={(props) => (
								<SearchList {...props} type="freelancerecruiter" />
							)}
						/>
						<Route
							path="/freelancerecruiter/SearchDetail"
							component={SearchDetail}
						/>

						<ContentRoute path="/logout" component={Logout} />
					</div>
				) : (
					""
				)}

				{role === "candidate" ? (
					<div>
						<Route path="/candidate/SearchJobs" component={SearchJobs} />
						<Route
							path="/candidate/MyJobApplications"
							component={MyJobApplication}
						/>
						<Route path="/candidate/JobDetail" component={JobDetail} />
						<Route
							path="/candidate/SearchJobDetail"
							component={SearchJobDetail}
						/>
						<Route path="/candidate/messages" component={CandidateMessages} />
						<Route path="/candidate/profile" component={CandidateProfile} />
						<Route path="/candidate/savedJobs" component={SavedJobs} />
						<ContentRoute path="/logout" component={Logout} />
					</div>
				) : (
					""
				)}
				<Redirect to="/dashboard" />
				<Redirect to="error/error-v1" />
			</Switch>
		</Suspense>
	);
}
