import { Button, Modal, Input } from "antd";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { OUTLOOK_INBOX, OUTLOOK_REPLY_MAIL } from "../../../ApiUrl";
import { useSelector } from "react-redux";

const EmailDetailPopup = ({ show, setShow, emailDetail, setEmailDetail }) => {
  const [isReplying, setIsReplying] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const [inbox, setInbox] = useState([]);
  const [loading, setLoading] = useState(true);
  const [replyingEmail, setReplyingEmail] = useState(null);
  const users = useSelector(({ users }) => users);
  const storedEmail = localStorage.getItem("email");

  const handleClose = () => {
    setShow(false);
    setEmailDetail(undefined);
    setIsReplying(false);
    setReplyContent("");
  };

  const handleReplyClick = () => {
    if (loading) {
      console.error("Inbox is still loading, please wait...");
      return;
    }

    const email = inbox
      .flatMap((emailArr) => emailArr)
      .find((email) => email.internetMessageId === emailDetail.msgId);

    console.log("Found Email to Reply:", email);

    if (email) {
      setReplyingEmail(email);
      setReplyContent("");
      setIsReplying(true);
    } else {
      console.error("Email to reply not found.");
    }
  };

  const handleReplyContentChange = (e) => {
    setReplyContent(e.target.value);
  };

  useEffect(() => {
    if (!show) return; 

    async function fetchInbox() {
      try {
        setLoading(true);
        const userId = users.user._id;
        const response = await axios.get(OUTLOOK_INBOX, {
          headers: {
            Authorization: users.token,
          },
          params: { userId },
        });

        const groupedEmails = groupEmailsByConversation(response.data);
        setInbox(groupedEmails);
      } catch (error) {
        console.error("Error fetching inbox emails:", error);
      } finally {
        setLoading(false); 
      }
    }

    fetchInbox();
  }, [show, users.token, users.user._id]);

  const groupEmailsByConversation = (emails) => {
    const grouped = emails.reduce((acc, email) => {
      const threadId = email.conversationId || email.id;
      if (!acc[threadId]) {
        acc[threadId] = [];
      }
      acc[threadId].push(email);
      return acc;
    }, {});

    return Object.values(grouped);
  };

  const handleSendReply = async () => {
    if (!replyingEmail) return;

    const subject = Array.isArray(replyingEmail.subject)
      ? replyingEmail.subject.join(", ")
      : replyingEmail.subject;

    try {
      const response = await axios.post(
        OUTLOOK_REPLY_MAIL,
        {
          messageId: replyingEmail.id,
          to: replyingEmail.from.emailAddress,
          subject: `Re: ${subject}`,
          body: replyContent,
          from: storedEmail,
          userid: users.user._id,
        },
        {
          headers: {
            Authorization: users.token,
          },
        }
      );

      console.log("Reply sent successfully:", response.data);
      setIsReplying(false);
      setReplyContent("");
    } catch (error) {
      console.error(
        "Error sending reply:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div>
      <Modal
        visible={show}
        title="Email Detail"
        width={620}
        onCancel={handleClose}
        bodyStyle={{ height: 620 }}
        footer={
          <div style={{ textAlign: "right" }}>
            {!isReplying ? (
              <>
                <Button
                  onClick={() => handleReplyClick(emailDetail)}
                  style={{ marginRight: 8 }}
                  disabled={loading} 
                >
                  Reply
                </Button>
                <Button onClick={handleClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Input.TextArea
                  value={replyContent}
                  onChange={handleReplyContentChange}
                  rows={4}
                  placeholder="Enter your reply here..."
                />
                <Button
                  onClick={handleSendReply}
                  style={{ marginRight: 8, marginTop: 8 }}
                >
                  Send
                </Button>
                <Button
                  onClick={() => setIsReplying(false)}
                  style={{ marginRight: 8, marginTop: 8 }}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        }
      >
        <iframe
          srcDoc={emailDetail?.html || "N/A"}
          title="email detail"
          width={"100%"}
          height={"100%"}
        />
      </Modal>
    </div>
  );
};

export default EmailDetailPopup;
