import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { requestHeader } from "../../others/header";
import {
	AUTO_SOURCE_CANDIDATE_DELETE,
	CANDIDATE_LIST_AUTO_SOURCED,
} from "../../ApiUrl";

export const fetchCandidates = createAsyncThunk(
	"candidates/fetchCandidates",
	async ({ jobId, page, pageSize, search }, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				`${CANDIDATE_LIST_AUTO_SOURCED}/${jobId}?&isAutoSourced=true&page=${page}&size=${pageSize}&s=${search}`,
				requestHeader()
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
export const deleteCandidates = createAsyncThunk(
	"candidates/deleteCandidates",
	async ({ candidateIds, jobId }, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				AUTO_SOURCE_CANDIDATE_DELETE + "/delete",
				{ candidateIds, jobId },
				requestHeader()
			);
			return { ...response.data, candidateIds };
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

const candidateSlice = createSlice({
	name: "candidates",
	initialState: {
		list: [],
		filteredList: [],
		loading: false,
		error: null,
		page: 1,
		pageSize: 10,
		totalData: 0,
		search: "",
		currentPage: 1,
		selectedCandidateIds: [],
		listView: false,
		bestCandidates: [],
		goodCandidates: [],
		qualifiedCandidates: [],
		selectedFilters: [],
		deleteStatus: "idle",
		deleteError: null,
		shouldRefresh: false,
	},
	reducers: {
		setPage: (state, action) => {
			state.page = action.payload;
		},
		setPageSize: (state, action) => {
			state.pageSize = action.payload;
		},
		setSearch: (state, action) => {
			state.search = action.payload;
		},
		setSelectedCandidateIds: (state, action) => {
			state.selectedCandidateIds = action.payload;
		},
		setListView: (state, action) => {
			state.listView = action.payload;
		},
		setCandidateLabel: (state, action) => {
			const { candidateId, labelType } = action.payload;
			state[labelType] = [...state[labelType], candidateId];
		},
		clearCandidateLabel: (state, action) => {
			const { candidateId, labelType } = action.payload;
			state[labelType] = state[labelType].filter((id) => id !== candidateId);
		},
		setSelectedFilters: (state, action) => {
			state.selectedFilters = action.payload;
			state.filteredList = applyFiltersToList(
				state.list,
				state.selectedFilters,
				state
			);
		},
		setShouldRefresh: (state, action) => {
			state.shouldRefresh = action.payload;
		},

		applyFilters: (state) => {
			state.filteredList = applyFiltersToList(
				state.list,
				state.selectedFilters,
				state
			);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCandidates.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchCandidates.fulfilled, (state, action) => {
				state.loading = false;
				state.list = action.payload.data;
				state.filteredList = action.payload.data;
				state.totalData = action.payload.paginate.totalData;
				state.currentPage = action.payload.paginate.pageNo;
				state.pageSize = action.payload.paginate.size;
			})
			.addCase(fetchCandidates.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(deleteCandidates.pending, (state) => {
				state.deleteStatus = "loading";
			})
			.addCase(deleteCandidates.fulfilled, (state, action) => {
				state.deleteStatus = "succeeded";
				if (action.payload && Array.isArray(action.payload.candidateIds)) {
					state.list = state.list.filter(
						(candidate) =>
							!action.payload.candidateIds.includes(candidate.candidate._id)
					);
					state.filteredList = applyFiltersToList(
						state.list,
						state.selectedFilters,
						state
					);
				} else {
					console.error(
						"Invalid payload structure in deleteCandidates.fulfilled"
					);
				}
			})
			.addCase(deleteCandidates.rejected, (state, action) => {
				state.deleteStatus = "failed";
				state.deleteError = action.payload;
			});
	},
});
const applyFiltersToList = (list, selectedFilters, state) => {
	if (selectedFilters.length === 0) {
		return list;
	}
	return list.filter((candidate) => {
		const candidateId = candidate.candidate._id;
		return (
			(selectedFilters.includes("top") &&
				state.bestCandidates.includes(candidateId)) ||
			(selectedFilters.includes("good") &&
				state.goodCandidates.includes(candidateId)) ||
			(selectedFilters.includes("qualified") &&
				state.qualifiedCandidates.includes(candidateId))
		);
	});
};

export const {
	setPage,
	setPageSize,
	setSearch,
	setSelectedCandidateIds,
	setListView,
	setCandidateLabel,
	clearCandidateLabel,
	setSelectedFilters,
	applyFilters,
	setShouldRefresh,
} = candidateSlice.actions;

export default candidateSlice.reducer;
