import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  CREATE_OR_UPDATE_PREFERENCE,
  GET_JOB_OPENINGS,
  JOBS_LIST,
  JOB_BDM_LIST,
  JOB_DELETE,
  JOB_DETAIL,
  JOB_OPENINGS,
  JOB_RECRUITER_LIST,
  JOB_UPDATE,
  PREFERENCE_LIST,
} from "../../../ApiUrl";
import {
  Table,
  Card,
  Button,
  Popconfirm,
  Space,
  Select,
  Row,
  Col,
  Tooltip,
  InputNumber,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ColumnHeightOutlined,
} from "@ant-design/icons";
import { OpeningStatusList } from "../../pages/constant/constant";
import { getFormatDate } from "../../pages/utils/helpers";
import Search from "antd/lib/transfer/search";
import { useCallAPI } from "../../../hooks/useCallAPI";
import { handleSearch } from "../../../others/handler/handleSearch";
import { CustomSnackbar } from "../others/Snackbar";
import EditColumns from "../Modals/EditColumns";
import axios from "axios";
import { requestHeader } from "../../../others/header";
import Notes from "../Modals/Notes";

export function OpeningList_v2() {
  const users = useSelector(({ users }) => users);
  const createNewClientPath = users.role === "admin" ? 'admin' : users.role === "recruitmentmanager" ? 'recruitment-manager' : users.role === "bdmmanager" ? 'bdm-manager' : users.role;
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: localStorage.getItem("jobOpeningsPageSize") || 10,
  });
  const [search, setSearch] = useState("");
  const [visibleColumns, setVisibleColumns] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const JobOpenings = useCallAPI(JOB_OPENINGS);
  const DeleteJob = useCallAPI(JOB_DELETE);
  const UpdateOpeningStatus = useCallAPI(`${JOB_DETAIL}/status`);
  const HandleColumnView = useCallAPI(CREATE_OR_UPDATE_PREFERENCE);
  const ColumnViewList = useCallAPI(
    `${PREFERENCE_LIST}?field="job_openings_preference"`
  );
  const BDMList = useCallAPI(JOB_BDM_LIST);
  const RecruiterList = useCallAPI(JOB_RECRUITER_LIST);
  const UpdateJobOpenings = useCallAPI(JOB_UPDATE);
  const NotesData = useCallAPI(GET_JOB_OPENINGS);

  const timeoutRef = useRef(null);

  const onDelete = (recordId) => {
    DeleteJob.deletee(recordId)
      .then(() => {
        callJobOpenings();
      })
      .catch((error) => { });
  };

  const callJobOpenings = () => {
    // JobOpenings.getList({
    //   current_page: pagination.page,
    //   per_page: pagination.pageSize,
    //   sort_order: "desc",
    //   search: search,
    //   order: "created_at",
    //   dateRange: "",
    //   categories: "",
    //   status: "",
    //   company_id: "",
    // });
    JobOpenings.getList(
      `current_page=${pagination.page}&per_page=${pagination.pageSize}&search=${search}&`
    );
  };

  const handleColumn = (key, show) => {
    setColumns(
      columns.map((column) =>
        column.key === key ? { ...column, show: show } : column
      )
    );
  };

  const saveViewPreference = () => {
    HandleColumnView.post({
      job_openings_preference: columns?.map((col) => ({
        title: col.title,
        show: col.show,
        dataIndex: col.dataIndex,
      })),
    });
    setVisibleColumns(false);
  };

  useEffect(() => {
    if (! JobOpenings.loading) {
      callJobOpenings();
    }
  }, [pagination, search]);

  useEffect(() => {
    getListPreference();
  }, []);

  // useEffect(() => {
  //   if (!BDMList.loading) {
  //     let assignments = columns.findIndex((c) => c.dataIndex == "assignments");
  //     columns[assignments] = {
  //       title: "BDMs",
  //       dataIndex: "assignments",
  //       show: true,
  //       key: () => {
  //         this.dataIndex;
  //       },
  //       render: (text, record) => {
  //         const { assignments } = record;
  //         let bdms = assignments[0].assigned_bdm;
  //
  //         let selectedBDM = BDMList.data?.data?.filter((bdm) =>
  //           bdms?.includes(bdm._id)
  //         );
  //         return selectedBDM?.map((bdm) => bdm?.display_name).join(", ");
  //       },
  //     };
  //   }
  // }, [BDMList.loading]);
  //
  // useEffect(() => {
  //   if (!RecruiterList.loading) {
  //     let recruiters = columns.findIndex((c) => c.dataIndex == "recruiters");
  //     recruiters &&
  //       (columns[recruiters] = {
  //         title: "Recruiters",
  //         dataIndex: "recruiters",
  //         show: true,
  //         key: () => {
  //           this.dataIndex;
  //         },
  //         render: (text, record) => {
  //           const { assignments } = record;
  //           let recruiters = assignments[0]?.assigned_recruiter;
  //           let selectedRecuriter = RecruiterList.data?.data?.filter((rec) =>
  //             recruiters?.includes(rec._id)
  //           );
  //           return selectedRecuriter
  //             ?.map((rec) => rec?.display_name)
  //             .join(", ");
  //         },
  //       });
  //   }
  // }, [RecruiterList.loading]);

  const getColumnPreference = async () => {
    try {
      await ColumnViewList.getList(); // Ensure this function is correctly fetching the data
      const data = ColumnViewList?.data?.data;

      if (data) {
        const updatedColumns = columns.map((column) => {
          const preference = data?.job_openings_preference?.find(
            (pref) =>
              JSON.stringify(pref.title) === JSON.stringify(column.title)
          );
          if (preference) {
            return { ...column, show: preference.show };
          }
          return column;
        });
        setColumns(updatedColumns);
      }
    } catch (error) { }
  }; //need to add it

  const getListPreference = () => {
    axios
      .get(
        `${PREFERENCE_LIST}?field="job_openings_preference"`,
        requestHeader()
      )
      .then((response) => {
        const updatedColumns = columns.map((column) => {
          const preference = response.data.data.job_openings_preference.find(
            (pref) =>
              JSON.stringify(pref.title) === JSON.stringify(column.title)
          );
          if (preference) {
            return { ...column, show: preference.show };
          }
          return column;
        });

        setColumns(updatedColumns);
      })
      .catch((error) => { });
  };

  const handleNotes = (value) => {
    // setShowNotes(false);
    UpdateJobOpenings.put({ notes: value }, selectedRow._id);
  };

  const getNotes = (record) => {
    NotesData.get(record.opening_id, "fields='notes,opening_id'");
    setSelectedRow(record);
    setShowNotes(true);
  };

  const [columns, setColumns] = useState([
    {
      // title: "Opening Title",
      dataIndex: "opening_title",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (data, record) => {
        // return date ? getFormatDate(date) : "";

        return (
          <Button
            onClick={() => {
              getNotes(record);
            }}
          >
            N
          </Button>
        );
      },
    },
    {
      title: "Opening Title",
      dataIndex: "opening_title",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (data, record) => {
        return (
          <NavLink
            to={{
              pathname: `/${createNewClientPath}/opening-detail`,
              state: { record: record },
            }}
          >
            {data}
          </NavLink>
        );
      },
    },
    {
      title: "Opening id",
      dataIndex: "opening_id",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => (
        <Typography.Text copyable>
          {record.opening_id}
        </Typography.Text>
      )
    },
    {
      title: "Skills",
      dataIndex: "required_skills",
      show: true,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Visa",
      dataIndex: "visa_type",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Experience",
      dataIndex: "required_experience",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Pay By",
      dataIndex: "pay_currency",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Pay Type",
      dataIndex: "pay_type",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Address",
      // dataIndex: ["country"],
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => {
        const { country, state, city, zip_code } = record;
        return `${country}, ${state}, ${city}, ${zip_code}`;
      },
    },
    {
      title: "Vacancy",
      dataIndex: "number_of_openings",
      show: true,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Candidates",
      dataIndex: "candidate_submission_count",
      show: true,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Autosourced",
      dataIndex: "autosourced_candidates_count",
      show: true,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Description",
      dataIndex: "short_description",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Type",
      dataIndex: "employment_type",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Experience Level",
      dataIndex: "experience_level",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Interview Type",
      dataIndex: "interview_type",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Salary",
      // dataIndex: "short_description",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => {
        const {
          salary_range_from,
          salary_range_to,
          currency,
          salary_type,
        } = record;
        return `${salary_range_from}-${salary_range_to} ${currency}/${salary_type}`;
      },
    },
    {
      title: "Interviews",
      dataIndex: "short_description",
      show: false,
      key: () => {
        this.dataIndex;
      },
    },
    {
      title: "Recruiters",
      dataIndex: "recruiterDetails",
      show: false,
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => {
        const { recruiterDetails, freelancerDetails} = record;
        const allRecruiter=recruiterDetails?.concat(freelancerDetails);
        return allRecruiter
              ?.map((rec) => rec?.display_name)
              .join(", ");
        // return selectedBDM;
      },
    },
    {
      title: "BDMs",
      dataIndex: "bdmDetails",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => {
        const { bdmDetails } = record;
        return bdmDetails
              ?.map((bdm) => bdm?.display_name)
              .join(", ");
        // return selectedBDM;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (Code, record, index) => {
        return (
          <Select
            placeholder="Select Status Name"
            value={Code}
            onChange={async (status) => {
              await UpdateOpeningStatus.put({ status, status }, record._id);
              callJobOpenings();
            }}
            style={{ width: "100%" }}
          >
            <Option value={""}>{"Select"}</Option>
            {OpeningStatusList !== undefined &&
              OpeningStatusList.map((status, index) => (
                <Option key={index.toString()} value={status.value}>
                  {status.label}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Posted Date",
      dataIndex: "created_at",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (date) => {
        return date ? getFormatDate(date) : "";
      },
    },
    {
      title: "Action",
      dataIndex: "",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (text, record, index) => (
        <Space size="middle">
          <NavLink
            to={{
              pathname: `/${createNewClientPath}/opening-detail`,
              state: { record: record },
            }}
          >
            <Button type="primary" icon={<EyeOutlined />} />
          </NavLink>
          <NavLink
            to={{
              pathname: `/${createNewClientPath}/add-opening`,
              state: { record: record },
            }}
          >
            <Button
              // onClick={(e) => {
              //   this.onEdit(record._id, e);
              // }}
              type="primary"
              icon={<EditOutlined />}
            />
          </NavLink>
          <NavLink
            to={{
              state: { record: record },
            }}
          >
            <Popconfirm
              title="Are you sure you want to delete this opening ?"
              onConfirm={() => onDelete(record._id)}
              icon={<DeleteOutlined style={{ color: "red" }} />}
            >
              <Button type="danger" icon={<DeleteOutlined />} />
            </Popconfirm>
          </NavLink>
        </Space>
      ),
    },
  ]);

  useEffect(() => {
    // Set the initial state from the history state or default to page 1
    const initialPage = window.history.state?.page || 1;
    // setCurrentPage(initialPage);
    setPagination({ ...pagination, page: initialPage });

    const handlePopState = (event) => {
      const page = event.state?.page || 1;
      // setCurrentPage(page);

      setPagination({ ...pagination, page: page });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div>
      <EditColumns
        columns={columns}
        isVisible={visibleColumns}
        handleCancel={() => {
          setVisibleColumns(false);
        }}
        handleColumn={handleColumn}
        save={saveViewPreference}
      />

      <Notes
        handleCancel={() => setShowNotes(false)}
        isVisible={showNotes}
        handleNotes={handleNotes}
        notes={NotesData}
        jobOpenings={selectedRow}
        user={users}
        updateNoteState={setShowNotes}
      />

      {/* <Filters callBack={(e) => {}} showCategory={true} showCompany={false} />  */}
      <CustomSnackbar
        success={
          DeleteJob.success ||
          UpdateOpeningStatus.success ||
          UpdateJobOpenings.success
        }
        error={
          DeleteJob.error ||
          UpdateOpeningStatus.error ||
          UpdateJobOpenings.error
        }
      />

      <div className="row mt-4">
        <div className="col-lg-12">
          <div className={`card card-custom card-stretch gutter-b`}>
            <div className="card-body py-3 px-3">
              <Card
                title="Job Openings List"
                bordered={false}
                className="px-0 py-0"
                extra={
                  <NavLink to={`/${createNewClientPath}/add-opening`}>
                    <Button type="primary">Add New Opening</Button>
                  </NavLink>
                }
              >
                <Row
                  style={{ width: "100%" }}
                  align="middle"
                  justify="space-between"
                >
                  <Col>
                    <Search
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e, timeoutRef, setSearch)}
                      style={{ width: 200 }}
                    />
                  </Col>

                  <Col>
                    <Space>
                      <Tooltip title="set page size">
                        <InputNumber
                          min={1}
                          value={pagination.pageSize}
                          onChange={(value) => {
                            setPagination({ ...pagination, pageSize: value });
                            localStorage.setItem("jobOpeningsPageSize", value);
                          }}
                          style={{ width: 100, textAlign: "center" }}
                        />
                      </Tooltip>

                      <Button
                        onClick={() => {
                          setVisibleColumns(true);
                        }}
                        icon={<ColumnHeightOutlined />}
                      >
                        Columns
                      </Button>

                      {/* <GlobalSettings
                        onClickLinkedIn={handleLinkedInInviationModal}
                      /> */}
                    </Space>
                  </Col>
                </Row>
                <br />
                <Table
                  pagination={{
                    total: JobOpenings.data?.paginate?.totalData,
                    showSizeChanger: false,
                    pageSize: pagination.pageSize,
                    current: pagination.page,
                    onChange(current) {
                      setPagination({ ...pagination, page: current });
                      window.history.pushState(
                        { page: current },
                        "",
                        `?page=${current}`
                      );
                    },
                  }}
                  dataSource={JobOpenings.data?.data}
                  columns={columns.filter((column) => column.show)}
                  loading={
                    JobOpenings.loading ||
                    DeleteJob.loading ||
                    UpdateOpeningStatus.loading ||
                    BDMList.loading ||
                    RecruiterList.loading
                  }
                  // scroll={{ x: 2000 }}
                  // sticky
                  style={{
                    overflowX: "scroll",
                    height: "500px",
                  }}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
