import axios from "axios";
import { GET_RECRUITERS_URL } from "./../../../../ApiUrl";
import { store } from "../../../../redux";
import { requestHeader } from "../../../../others/header";

export const TMP_REGISTER_URL = "api/auth/register";



export const getRecruiters = () =>
  new Promise((resolve, reject) => {
    axios
      .post(GET_RECRUITERS_URL, { title: "", name: "" }, requestHeader())
      .then((res) => resolve(res))
      .catch((err) => Error(err));
  });
