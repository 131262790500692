import {
	Avatar,
	Badge,
	Card,
	Checkbox,
	Divider,
	Dropdown,
	Form,
	InputNumber,
	Menu,
	Modal,
	Select,
	Switch,
	Table,
	Tag,
	Tooltip,
	Typography,
	message,
	Input,
	Button,
	Space,
	Row,
	Col,
	Pagination,
	Radio,
	Timeline,
	Tabs,
	Image,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import ResizeObserver from "rc-resize-observer";
import {
	SearchOutlined,
	FilterOutlined,
	PlusOutlined,
	ColumnHeightOutlined,
	EyeOutlined,
	MoreOutlined,
	FileTextOutlined,
	SendOutlined,
	MessageOutlined,
	TableOutlined,
	DeleteOutlined,
	EditOutlined,
	LinkedinOutlined,
	MailOutlined,
	GlobalOutlined,
	LinkOutlined, CheckCircleOutlined, CloseCircleOutlined, MailTwoTone,
} from "@ant-design/icons";
import axios from "axios";
import {
	AUTO_SOURCE_CANDIDATE_DELETE,
	CANDIDATE_LIST_AUTO_SOURCED,
	CANDIDATE_NOTES,
	CANDIDATE_NOTE_DELETE,
	CANDIDATE_NOTE_UPDATE,
	CANDIDATE_UPDATE_PARTIALLY,
	CREATE_OR_UPDATE_PREFERENCE,
	GET_JOB_OPENINGS,
	IMAGE_CANDIDATE_URL,
	JOB_UPDATE,
	PREFERENCE_LIST,
	SEARCH_CONTACTS,
	SEND_BULK_EMAIL,
	SENT_LINKEDIN_INVITATION,
} from "../../../ApiUrl";
import { requestHeader } from "../../../others/header";
import { NavLink, useLocation } from "react-router-dom";
import "../../../CSS/table.css";
import TextArea from "antd/lib/input/TextArea";
import { showError } from "../utils";
import PdfViewer from "../../modules/others/pdfViewer";
import { GlobalSettings } from "../../modules/DynamicList/Settings";
import { useCallAPI } from "../../../hooks/useCallAPI";
import Notes from "../../modules/Modals/Notes";
import { useDispatch, useSelector } from "react-redux";
import { CustomSnackbar } from "../../modules/others/Snackbar";
import {
	CheckBox,
	Email,
	EmailOutlined,
	List,
	Note, ReplyOutlined,
	SortOutlined,
} from "@material-ui/icons";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import {
	applyFilters,
	fetchCandidates,
	setSearch,
	setSelectedCandidateIds,
	clearCandidateLabel,
	setCandidateLabel,
	setListView,
	setPage,
	setPageSize,
	setSelectedFilters,
	deleteCandidates,
	setShouldRefresh,
} from "../../../redux/reducers/candidatesSlice";
import { Container } from "react-bootstrap";
import { Link } from "@material-ui/core";
export default function CandidateDynamicList2({ type = "admin" }) {
	const users = useSelector(({ users }) => users);
	const dispatch = useDispatch();
	const {
		list: candidateList,
		filteredList: filteredCandidates,
		loading,
		error,
		page,
		totalData,
		currentPage,
		pageSize: reduxPageSize,
		search,
		selectedCandidateIds,
		listView,
		bestCandidates,
		goodCandidates,
		qualifiedCandidates,
		selectedFilters,
		deleteStatus,
		deleteError,
		shouldRefresh,
	} = useSelector((state) => state.candidates);
	const pageSize = reduxPageSize || 10;
	const [showCV, setShowCv] = useState(false);
	const [showEmailForm, setShowEmailForm] = useState(false);
	const [cvPath, setCVPath] = useState("");
	const [emailData, setEmailData] = useState({
		to: [],
		subject: "",
		body: "",
		candidateId:""
	});
	const [openDltModal, setOpenDltModal] = useState(false);
	const [idsToDelete, setIdsToDelete] = useState([]);
	const [isDeleting, setIsDeleting] = useState(false);
	 const [isEmailHistoryModalVisible, setIsEmailHistoryModalVisible] = useState(false);
  const [selectedEmailHistoryRecord, setSelectedEmailHistoryRecord] = useState(null);

  const handleChatClick = (emailHistory) => {
    setSelectedEmailHistoryRecord(emailHistory);
    setIsEmailHistoryModalVisible(true);
  };

  const handleModalClose = () => {
    setIsEmailHistoryModalVisible(false);
    setSelectedEmailHistoryRecord(null);
  };
	const [columns, setColumns] = useState([
		{
			// title: "Opening Title",
			dataIndex: ["candidate", "_id"],
			show: true,
			// fixed: "left",
			mandatory: true,
			key: () => {
				this.dataIndex;
			},
			render: (data, record) => {
				// return date ? getFormatDate(date) : "";
				// console.log('get notes in column', record,);
				return (
					<Button
						onClick={() => {
							getNotes(record);
						}}
					>
						N
					</Button>
				);
			},
			// width: " .4%",
		},
		{
			title: "Display Name",
			show: true,
			mandatory: true,
			dataIndex: ["candidate", "first_name"],
			key: () => {
				this.dataIndex;
			},
			render: (text, records) => (
				<span style={{ width: "max-content" }}>
					<Space size="middle">
						<NavLink
							to={{
								pathname: `/${type}/candidateProfileDetail`,
								state: {
									item: { ...records.candidate, opening_id: record.opening_id },
								},
							}}
						>
							<p>
								{records.candidate.first_name} {records.candidate.last_name}
							</p>
						</NavLink>
					</Space>

					{records.candidate.attachments ? (
						<Button
							onClick={() => setShowCv(true)}
							icon={<FileTextOutlined />}
						/>
					) : null}

					{setCVPath(
						`${process.env.REACT_APP_BASE_URL}/upload/candidate/${records.candidate.attachments}`
					)}
				</span>
			),
		},
		{
			title: "Email",
			show: true,
			mandatory: false,

			dataIndex: ["candidate", "email"],
			key: () => {
				this.dataIndex;
			},
			render: (text, records) => {
				// console.log(records.candidate);
				return (
					<span>
						<span style={{ marginRight: "10px" }}>
							{records?.candidate?.email?.split(",").map((email, index) => (
								<Typography.Text key={index} copyable>
									{email.trim()}
								</Typography.Text>
							))}
						</span>

						{isValidEmails(records.candidate.email) ? (
							<Button
								onClick={() => {
									onClickSendEmail(records);
								}}
								icon={<SendOutlined />}
							/>
						) : null}
					</span>
				);
			},
		},
		{
			title: "Email Status",
			show: true,
			mandatory: false,
			dataIndex: ["candidate", "email"],
			key: () => {
				this.dataIndex;
			},
			render: (text, records) => {
				const hasSent = records.emailHistory.some(email => email.is_sent);
  // Check if emailHistory has any object with is_open as true
  const hasOpen = records.emailHistory.some(email => email.is_open);
  // Check if emailHistory has any object with is_replied as true
  const hasReplied = records.emailHistory.some(email => email.is_replied);
  // Check if emailHistory has any object with is_bounced as true
  const hasBounced = records.emailHistory.some(email => email.is_bounced);

  return (
    <span>
      <span style={{ marginRight: '10px' }}>
        {hasSent ? (
            <Typography.Text>Send <CheckCircleOutlined style={{ color: 'green' }} /></Typography.Text>
        ) : (
          <Typography.Text>Send <CloseCircleOutlined style={{color: 'red'}}/></Typography.Text>
        )}
      </span>
      <span style={{ marginRight: '10px' }}>
        {hasOpen ? (
            <Typography.Text>Opened <MailTwoTone twoToneColor="#52c41a" /></Typography.Text>
        ) : null}
      </span>
      <span style={{ marginRight: '10px' }}>
        {hasReplied ? (
            <Typography.Text>Replied <ReplyOutlined style={{ color: '#52c41a' }} /></Typography.Text>
        ) : null}
      </span>
      <span style={{ marginRight: '10px' }}>
        {hasBounced ? (
            <Typography.Text>Bounced <CheckCircleOutlined style={{ color: '#52c41a' }} /></Typography.Text>
        ) : null}
      </span>
      {hasReplied && (
        <Button
          onClick={() => {
            handleChatClick(records.emailHistory);
          }}
          icon={<MessageOutlined />}
        />
      )}
    </span>
  );
			},
		},
		{
			title: "Mobile",
			show: true,
			mandatory: false,
			dataIndex: ["candidate", "mobile"],
			key: () => {
				this.dataIndex;
			},
			render: (text, records) => (
				<>
					{records.candidate?.mobile?.split(",").map((mobile, index) => (
						<Typography.Text key={index} copyable>
							{" "}
							{mobile}
						</Typography.Text>
					))}
				</>
			),
		},
		{
			title: "Experience (yrs)",
			show: true,
			mandatory: false,
			dataIndex: ["candidate", "total_work_exp_year"],
			key: () => {
				this.dataIndex;
			},
			render: (text, records) => (
				<span>
					{records.candidate.total_work_exp_year
						? records.candidate.total_work_exp_year
						: "0"}{" "}
					years{" "}
					{records.candidate.total_work_exp_month
						? records.candidate.total_work_exp_month
						: "0"}{" "}
					months
				</span>
			),
		},
		{
			title: "Profile Summary",
			show: true,
			mandatory: false,
			dataIndex: ["candidate", "profile_summary"],
			key: () => {
				this.dataIndex;
			},
			render: (text, records) => (
				<ExpandableContent
					content={records.candidate.profile_summary}
					maxHeight={48}
					showLessText="Read Less"
					showMoreText="Read More"
				/>
			),
		},
		{
			title: "Gender",
			show: true,
			mandatory: false,
			dataIndex: ["candidate", "gender"],
			key: () => {
				this.dataIndex;
			},
		},
		{
			title: "Home Town",
			show: true,
			mandatory: false,
			dataIndex: ["candidate", "home_town"],
			key: () => {
				this.dataIndex;
			},
		},
		{
			title: "Permanent Address",
			show: false,
			mandatory: false,
			dataIndex: ["candidate", "permanent_address"],
			key: () => {
				this.dataIndex;
			},
		},
		{
			title: "Desired Job",
			show: false,
			mandatory: false,
			dataIndex: ["candidate", "desired_job_type"],
			key: () => {
				this.dataIndex;
			},
		},
		{
			title: "Location",
			show: false,
			mandatory: false,
			dataIndex: ["candidate", "current_location"],
			key: () => {
				this.dataIndex;
			},
		},
		{
			title: "Job Category",
			show: false,
			mandatory: true,
			dataIndex: ["candidate", "job_category"],
			key: () => {
				this.dataIndex;
			},
		},
		{
			title: "CTC",
			show: false,
			mandatory: false,
			dataIndex: ["candidate", "current_ctc"],
			key: () => {
				this.dataIndex;
			},
		},
		{
			title: "LinkedIn Invitation",
			show: false,
			mandatory: false,
			dataIndex: ["candidate", "linkedIn_invitation_status"],
			key: () => {
				this.dataIndex;
			},
			render: (text, records) => {
				const currentUserId = users.user._id;
				const invitationStatusArray =
					records.candidate.linkedIn_invitation_status || [];
				const invitationStatus = invitationStatusArray.find(
					(status) => status.sent_by === currentUserId
				);
				return invitationStatus
					? invitationStatus.status
					: "No Invitation Sent";
			},
		},
		{
			title: "Tags",
			show: true,
			mandatory: true,
			dataIndex: ["candidate", "tags"],
			key: () => {
				this.dataIndex;
			},
			render: (text, records) => (
				<span>
					{records.candidate.tags
						? records.candidate.tags.map((tag) => (
								<Tag key={tag} color="blue">
									{tag}
								</Tag>
						  ))
						: null}
				</span>
			),
		},
		{
			title: "Date of Birth",
			show: false,
			mandatory: false,
			dataIndex: ["candidate", "date_of_birth"],
			key: () => {
				this.dataIndex;
			},
		},
		{
			title: "LinkedIn",
			show: true,
			mandatory: false,
			dataIndex: ["candidate", "meta", "linkedin"],
			key: () => {
				this.dataIndex;
			},
			render: (text, records) => (
				<span>
					{records.candidate.meta?.linkedin ? (
						<a href={records.candidate.meta?.linkedin} target="_blank">
							<Button type="primary" icon={<EyeOutlined />} />
						</a>
					) : null}
				</span>
			),
		},
		{
			title: "Action",
			dataIndex: ["candidate", "_id"],
			show: true,
			// fixed: "right",
			mandatory: true,
			key: "action",
			render: (text, records) => {
				const candidateLabel = bestCandidates.includes(records.candidate._id)
					? "top"
					: goodCandidates.includes(records.candidate._id)
					? "good"
					: qualifiedCandidates.includes(records.candidate._id)
					? "qualified"
					: "";
				return (
					<Space direction="horizontal">
						<Space size="middle">
							<NavLink
								to={{
									pathname: `/${rolePath}/edit-candidate`,
									state: {
										item: {
											...records.candidate,
											opening_id: record.opening_id,
										},
									},
								}}
							>
								<Button type="primary" icon={<EditOutlined />} />
							</NavLink>
							<NavLink
								to={{
									pathname: `/${type}/candidateProfileDetail`,
									state: {
										item: {
											...records.candidate,
											opening_id: record.opening_id,
										},
									},
								}}
							>
								<Button type="primary" icon={<EyeOutlined />} />
							</NavLink>
							<Button
								type="danger"
								icon={<DeleteOutlined />}
								onClick={() => openDeleteModal(records.candidate._id)}
							></Button>
						</Space>
						<Space direction="vertical" size="middle">
							<Space direction="horizontal">
								<Radio.Group
									value={candidateLabel}
									onChange={(e) =>
										handleLabelChange(records.candidate._id, e.target.value)
									}
									size="small"
									style={{ display: "flex" }}
								>
									<Radio.Button value="top">Best</Radio.Button>
									<Radio.Button value="good">Good</Radio.Button>
									<Radio.Button value="qualified">Qualified</Radio.Button>
								</Radio.Group>
							</Space>
						</Space>
					</Space>
				);
			},
		},
	]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [showLinkedInInvitaion, setShowLinkedInInvitaion] = useState(false);

	const [selectionType, setSelectionType] = useState("checkbox");

	const location = useLocation();
	const { record } = location.state || {};
	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);
	const [showNotes, setShowNotes] = useState(false);

	const NotesData = useCallAPI(CANDIDATE_NOTES);
	const UpdateCandidate = useCallAPI(CANDIDATE_UPDATE_PARTIALLY);
	const UpdateNotes = useCallAPI(CANDIDATE_NOTE_UPDATE);
	const DeleteNotes = useCallAPI(CANDIDATE_NOTE_DELETE);
	const SendBulkEmail = useCallAPI(SEND_BULK_EMAIL);
	const SearchContact = useCallAPI(SEARCH_CONTACTS);
	const [globalLoading, setGlobalLoading] = useState({
		linkedInInvitation: false,
	});
	const [globalSuccess, setGlobalSuccess] = useState({
		status: false,
		message: null,
	});
	const [globalError, setGlobalError] = useState({
		status: false,
		message: null,
	});

	const searchContact = () => {
		const params = selectedRows.map((row) => ({
			candidateId: row.candidate._id,
			firstName: row.candidate.first_name,
			middleName: row.candidate.middle_name,
			lastName: row.candidate.last_name,
			address: row.candidate.current_location,
		}));
		SearchContact.post({ search: params });
	};

	// const onClickSendEmail = (records) => {
	//     setShowEmailForm(true);

	//     let emails = records
	//         ? records.candidate.email.split(",").map((email) => email.trim())
	//         : selectedRows.flatMap((row) =>
	//             row.candidate.email.split(",").map((email) => email.trim())
	//         );
	//     setEmailData({ ...emailData, to: emails });
	// };
	// const onClickSendEmail = (records) => {
	// 	setShowEmailForm(true);

	// 	let emails = records
	// 		? [records.candidate.email].flatMap((email) =>
	// 				email.split(",").map((e) => e.trim())
	// 		  )
	// 		: selectedRows.flatMap((row) =>
	// 				row.candidate.email.split(",").map((email) => email.trim())
	// 		  );

	// 	setEmailData({ ...emailData, to: emails });
	// };
	// const onClickSendEmail = (records) => {
	// 	setShowEmailForm(true);
	// 	let emails = records
	// 		? [records.candidate.email].flatMap((email) =>
	// 				email.split(",").map((e) => e.trim())
	// 		  )
	// 		: selectedRows.flatMap((row) =>
	// 				row.candidate.email.split(",").map((email) => email.trim())
	// 		  );
	// 	setEmailData({ ...emailData, to: emails });
	// };

	const onClickSendEmail = (records) => {
		setShowEmailForm(true);
		let emails = [];

		if (records && records.candidate) {
			// Single candidate (from card view)
			emails = records.candidate.email.split(",").map((email) => email.trim());
		} else if (selectedRows && selectedRows.length > 0) {
			// Multiple candidates (from table view or card view multiple selection)
			emails = selectedRows.flatMap((row) =>
				row.candidate.email.split(",").map((email) => email.trim())
			);
		}

		setEmailData({ ...emailData, to: emails });
	};

	const handleListPreference = () => {
		setIsModalVisible(false);
		const updatedPreference = columns.map((col) => ({
			title: col.title,
			show: col.mandatory ? true : col.show,
			dataIndex: col.dataIndex,
		}));

		axios
			.post(
				CREATE_OR_UPDATE_PREFERENCE,
				{
					candidate_preference: updatedPreference,
				},
				requestHeader()
			)
			.then((response) => {})
			.catch((error) => {});
	};
	const getNotes = (record) => {
		if (record?.candidate?._id) {
			NotesData.get(record?.candidate?._id);
			setSelectedRow(record?.candidate);
			setShowNotes(true);
		}
	};

	const getListPreference = () => {
		axios
			.get(PREFERENCE_LIST, requestHeader())
			.then((response) => {
				const updatedColumns = columns.map((column) => {
					const preference = response.data.data.candidate_preference.find(
						(pref) =>
							JSON.stringify(pref.title) === JSON.stringify(column.title)
					);
					if (preference) {
						return {
							...column,
							show: column.mandatory ? true : preference.show,
						};
					}
					return column;
				});

				setColumns(updatedColumns);
			})
			.catch((error) => {});
	};
	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleSwitchChange = (key, show) => {
		setColumns(
			columns.map((column) =>
				column.key === key && !column.mandatory
					? { ...column, show: show }
					: column
			)
		);
	};
	const rowSelection = {
		selectedRowKeys: selectedCandidateIds,
		onChange: (selectedRowKeys, selectedRows) => {
			dispatch(setSelectedCandidateIds(selectedRowKeys));
			setSelectedRows(selectedRows);
		},
		getCheckboxProps: (record) => ({
			disabled: record.name === "Disabled User",
			name: record.name,
		}),
	};
	const handleDeleteNotes = (id) => {
		DeleteNotes.deletee(`${selectedRow._id}/${id}`);
	};

	useEffect(() => {
		getListPreference();
	}, []);

	function isValidEmails(emails) {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (typeof emails === "string") {
			return emails.split(",").every((email) => emailRegex.test(email.trim()));
		}
		return false;
	}

	const [form] = Form.useForm();

	useEffect(
		() => {
			if (
				record &&
				(candidateList.length === 0 || search !== prevSearch.current)
			) {
				dispatch(
					fetchCandidates({ jobId: record.opening_id, page, pageSize, search })
				);
			}
		},
		[dispatch, record, page, pageSize, search, candidateList.length],
		shouldRefresh
	);
	useEffect(() => {
		if (shouldRefresh && record) {
			dispatch(
				fetchCandidates({ jobId: record.opening_id, page, pageSize, search })
			);
			dispatch(setShouldRefresh(false));
		}
	}, [shouldRefresh, dispatch, record, page, pageSize, search]);
	const prevSearch = useRef(search);
	// console.log("candidate list", candidateList);
	useEffect(() => {
		prevSearch.current = search;
	}, [search]);

	useEffect(() => {
		dispatch(applyFilters());
	}, [dispatch]);

	const handleSearch = (e) => {
		const value = e.target.value;
		dispatch(setSearch(value));
		dispatch(applyFilters());
	};

	const handleDeleteCandidate = async () => {
		setIsDeleting(true);
		try {
			const resultAction = await dispatch(
				deleteCandidates({
					candidateIds: idsToDelete,
					jobId: record.opening_id,
				})
			);
			if (deleteCandidates.fulfilled.match(resultAction)) {
				message.success("Candidate(s) deleted successfully");
				setOpenDltModal(false);
				dispatch(
					fetchCandidates({ jobId: record.opening_id, page, pageSize, search })
				);
			} else if (deleteCandidates.rejected.match(resultAction)) {
				throw new Error(
					resultAction.error.message || "Failed to delete candidate(s)"
				);
			}
		} catch (error) {
			console.error("Error deleting candidate:", error);
			message.error(error.message);
		} finally {
			setIsDeleting(false);
		}
	};
	const handleRefresh = () => {
		dispatch(
			fetchCandidates({ jobId: record.opening_id, page, pageSize, search })
		);
	};
	useEffect(() => {
		if (deleteStatus === "failed" && deleteError) {
			message.error(deleteError.message || "Failed to delete candidate(s)");
		}
	}, [deleteStatus, deleteError]);
	const handleDeleteMultipleCandidates = () => {
		openDeleteModal(selectedCandidateIds);
	};

	const openDeleteModal = (candidateIds) => {
		setOpenDltModal(true);
		setIdsToDelete(Array.isArray(candidateIds) ? candidateIds : [candidateIds]);
	};

	const handleCancelDeleteModal = () => {
		setOpenDltModal(false);
	};

	const handleCheckboxChange = (candidateId) => {
		const newSelectedIds = selectedCandidateIds.includes(candidateId)
			? selectedCandidateIds.filter((id) => id !== candidateId)
			: [...selectedCandidateIds, candidateId];

		dispatch(setSelectedCandidateIds(newSelectedIds));

		// Update selectedRows based on the new selection
		const newSelectedRows = filteredCandidates.filter((candidate) =>
			newSelectedIds.includes(candidate.candidate._id)
		);
		setSelectedRows(newSelectedRows);
	};

	const handleSelectAll = () => {
		const allCandidateIds = filteredCandidates.map(
			(candidate) => candidate.candidate._id
		);
		const newSelectedIds =
			selectedCandidateIds.length === allCandidateIds.length
				? []
				: allCandidateIds;
		dispatch(setSelectedCandidateIds(newSelectedIds));

		const newSelectedRows =
			newSelectedIds.length === 0 ? [] : filteredCandidates;
		setSelectedRows(newSelectedRows);
	};
	const handleLabelChange = (candidateId, label) => {
		dispatch(clearCandidateLabel({ candidateId, labelType: "bestCandidates" }));
		dispatch(clearCandidateLabel({ candidateId, labelType: "goodCandidates" }));
		dispatch(
			clearCandidateLabel({ candidateId, labelType: "qualifiedCandidates" })
		);

		if (label === "top") {
			dispatch(setCandidateLabel({ candidateId, labelType: "bestCandidates" }));
		} else if (label === "good") {
			dispatch(setCandidateLabel({ candidateId, labelType: "goodCandidates" }));
		} else if (label === "qualified") {
			dispatch(
				setCandidateLabel({ candidateId, labelType: "qualifiedCandidates" })
			);
		}
	};

	const handleChangeLabel = (selected) => {
		dispatch(setSelectedFilters(selected));
	};

	const handleView = () => {
		dispatch(setListView(!listView));
	};
	const sendInvitation = async (values) => {
		try {
			setGlobalLoading({ ...globalLoading, linkedInInvitation: true });

			const data = [];

			for (let i of values.displayName) {
				const row = selectedRows.filter((row) => row.candidate.first_name == i);
				let msg = values.message;
				msg = msg.replace(/{{name}}/g, row[0]?.candidate?.first_name);
				msg = msg.replace(/{{email}}/g, row[0]?.candidate?.email);
				msg = msg.replace(/{{phone}}/g, row[0]?.candidate?.phone);

				if (row[0].candidate?.meta?.linkedin) {
					data.push({
						linkedin_link: row[0].candidate?.meta?.linkedin,
						message: msg,
						candidate_id: row[0].candidate._id,
					});
				}
			}

			if (data.length) {
				axios
					.post(SENT_LINKEDIN_INVITATION, data, requestHeader())
					.then((response) => {
						setGlobalSuccess({
							status: true,
							message: "LinkedIn Invitation has been sent successfully!",
						});
					})
					.catch((error) => {
						setGlobalError({
							status: true,
							message: "Failed to send linkedIn invitation!",
						});
					});
			}

			form.setFieldsValue({
				message: "",
			});
		} catch (error) {
		} finally {
			setGlobalLoading({ ...globalLoading, linkedInInvitation: false });

			setTimeout(() => {
				setGlobalSuccess({
					status: false,
					message: null,
				});
				setGlobalError({
					status: false,
					message: null,
				});
			}, 3000);
		}
	};

	const sendEmail = (emailBody) => {
		const data = [];
		for (let email of emailBody.to) {
			const row = selectedRows.find((row) =>
				row.candidate.email
					.split(",")
					.map((e) => e.trim())
					.includes(email)
			);
console.log(row);

			if (row) {
				let msg = emailBody.message;
				msg = msg.replace(/{{name}}/g, row.candidate.first_name);
				msg = msg.replace(/{{email}}/g, email);
				msg = msg.replace(/{{phone}}/g, row.candidate.mobile);

				data.push({
					to: email,
					message: msg,
					subject: emailBody.subject,
					candidateId:row.candidate._id
				});
			}
		}

		if (data.length) {
			SendBulkEmail.post({ email: data, pauseSecond: emailBody.pause,jobId:record.opening_id });
		}

		form.resetFields();
		setShowEmailForm(false);
	};
	const handleCloseEmailModal = () => {
		setShowEmailForm(false);
		form.resetFields();
		setEmailData({
			to: [],
			subject: "",
			body: "",
			candidateId:""
		});
	};
	useEffect(() => {
		form.setFieldsValue(emailData);
	}, [emailData, form]);
	const handleUpdateNotes = (value, id) => {
		UpdateNotes.patch(value, `${selectedRow._id}/${id}`);
	};

	const onFinishFailed = (errorInfo) => {};

	const handleLinkedInInviationModal = () => {
		if (selectedRows.length > 0) {
			setShowLinkedInInvitaion(true);
			form.setFieldsValue({
				displayName: selectedRows.map((row) => row.candidate.first_name),
			});
		} else {
			message.warning(
				"Please select at least one candidate for LinkedIn invitation."
			);
		}
	};

	useEffect(() => {
		const newSelectedRows = filteredCandidates.filter((candidate) =>
			selectedCandidateIds.includes(candidate.candidate._id)
		);
		setSelectedRows(newSelectedRows);
	}, [selectedCandidateIds, filteredCandidates]);
	const timeoutRef = useRef(null);

	const textAreaRef = useRef(null);
	const [textValue, setTextValue] = useState("");

	const insertVariable = (value) => {
		form.setFieldsValue({
			message: form.getFieldValue("message") + ` {{${value}}}`,
		});
	};

	useEffect(() => {
		form.setFieldsValue({
			displayName: selectedRows?.map((row) => row.candidate.first_name),
		});
	}, [selectedRows]);

	const maxChars = 300;

	const handleNotes = (value) => {
		UpdateCandidate.patch({ multipleNotes: value }, selectedRow._id);
	};

	const handleViewCandidate = (candidate) => {
		localStorage.setItem("lastViewedCandidate", JSON.stringify(candidate));
	};

	useEffect(() => {
		const lastViewedCandidate = JSON.parse(
			localStorage.getItem("lastViewedCandidate")
		);
		if (lastViewedCandidate) {
			const element = document.getElementById(
				`candidate-${lastViewedCandidate._id}`
			);
			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, []);
	const handleTableChange = (pagination, filters, sorter) => {
		dispatch(setPage(pagination.current));
		dispatch(setPageSize(pagination.pageSize));
		dispatch(
			fetchCandidates({
				jobId: record.opening_id,
				page: pagination.current,
				pageSize: pagination.pageSize,
			})
		);
	};
	const rolePath =
		users.role === "recruitmentmanager"
			? "recruitment-manager"
			: users.role === "bdmmanager"
			? "bdm-manager"
			: users.role;
	const ExpandableContent = ({
		content,
		maxHeight = 48,
		showMoreText = "Show more",
		showLessText = "Show less",
	}) => {
		const [showButton, setShowButton] = useState(false);
		const [isExpanded, setIsExpanded] = useState(false);
		const contentRef = useRef(null);

		useEffect(() => {
			if (contentRef.current) {
				setShowButton(contentRef.current.scrollHeight > maxHeight);
			}
		}, [content, maxHeight]);
		if (!content) {
			return (
				<Typography.Paragraph style={{ color: "grey" }}>
					Empty
				</Typography.Paragraph>
			);
		}
		return (
			<div>
				<div
					ref={contentRef}
					style={{
						maxHeight: isExpanded ? "none" : `${maxHeight}px`,
						overflow: "hidden",
						transition: "max-height 0.3s ease-out",
					}}
				>
					{content}
				</div>
				{showButton && (
					<Typography.Link onClick={() => setIsExpanded(!isExpanded)}>
						{isExpanded ? showLessText : showMoreText}
					</Typography.Link>
				)}
			</div>
		);
	};
	const LinkedInInvitationStatus = ({ candidate }) => {
		const currentUserId = users.user._id;
		const invitationStatusArray = candidate.linkedIn_invitation_status || [];
		const invitationStatus = invitationStatusArray.find(
			(status) => status.sent_by === currentUserId
		);

		const getStatusColor = (status) => {
			switch (status) {
				case "Sent":
					return "blue";
				case "Accepted":
					return "green";
				case "Rejected":
					return "red";
				default:
					return "default";
			}
		};
		return (
			<Space align="center">
				<LinkedinOutlined style={{ fontSize: "16px", color: "#0077B5" }} />
				<Typography.Text strong>LinkedIn Invitation:</Typography.Text>
				{invitationStatus ? (
					<Tag color={getStatusColor(invitationStatus.status)}>
						{invitationStatus.status}
					</Tag>
				) : (
					<Tag>No Invitation Sent</Tag>
				)}
			</Space>
		);
	};
	const EmailStatus = ({ emailHistory }) => {
		const hasSent = emailHistory.some(email => email.is_sent);
  // Check if emailHistory has any object with is_open as true
  const hasOpen = emailHistory.some(email => email.is_open);
  // Check if emailHistory has any object with is_replied as true
  const hasReplied = emailHistory.some(email => email.is_replied);
  // Check if emailHistory has any object with is_bounced as true
  const hasBounced = emailHistory.some(email => email.is_bounced);
		return (
			<Space align="center">
				<MailOutlined style={{fontSize: "16px", color: "#0077B5"}}/>
				<Typography.Text strong>Email Status:</Typography.Text>
      <span style={{marginRight: '10px'}}>
        {hasSent ? (
			<Typography.Text>Send <CheckCircleOutlined style={{color: 'green'}}/></Typography.Text>
		) : (
			<Typography.Text>Send <CloseCircleOutlined style={{color: 'red'}}/></Typography.Text>
		)}
      </span>
      <span style={{marginRight: '10px'}}>
        {hasOpen ? (
			<Typography.Text>Opened <MailTwoTone twoToneColor="#52c41a"/></Typography.Text>
		) : null}
      </span>
      <span style={{marginRight: '10px'}}>
        {hasReplied ? (
			<Typography.Text>Replied <ReplyOutlined style={{color: '#52c41a'}}/></Typography.Text>
		) : null}
      </span>
      <span style={{marginRight: '10px'}}>
        {hasBounced ? (
			<Typography.Text>Bounced <CheckCircleOutlined style={{color: '#52c41a'}}/></Typography.Text>
		) : null}
      </span>
					{hasReplied && (
						<Button
							onClick={() => {
								handleChatClick(emailHistory);
							}}
							icon={<MessageOutlined/>}
						/>
					)}
			</Space>
		);
	};
	return (
		<>
			{/* <Modal
				title="Select Columns"
				open={isModalVisible}
				onOk={handleListPreference}
				onCancel={handleCancel}
				style={{
					top: 0,
					right: 0,
					height: "100vh",
					position: "fixed",
					width: "300px",
					margin: 0,
					padding: 0,
				}}
				bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
			>
				{columns.map((column) => (
					<div key={column.key} style={{ marginBottom: "10px" }}>
						<Switch
							checked={column.show}
							onChange={(show) => handleSwitchChange(column.key, show)}
							style={{ marginLeft: "10px" }}
						/>{" "}
						<span>{column.title}</span>
					</div>
				))}
			</Modal> */}
			<Modal
				title="Select Columns"
				open={isModalVisible}
				onOk={handleListPreference}
				onCancel={handleCancel}
				style={
					{
      top: 0,
      right: 0,
      height: "100vh",
      position: "fixed",
      width: "300px",
      margin: 0,
      padding: 0,
      overflowY: "scroll"
    }
				}
				bodyStyle={{height: "calc(100% - 55px)", overflowY: "auto"}}
			>
				{columns.map((column) => (
					<div key={column.key} style={{ marginBottom: "10px" }}>
						<Switch
							checked={column.show}
							onChange={(show) => handleSwitchChange(column.key, show)}
							style={{ marginLeft: "10px" }}
							disabled={column.mandatory}
						/>{" "}
						<span>{column.title}</span>
						{column.mandatory && (
							<span style={{ color: "red" }}> (Mandatory)</span>
						)}
					</div>
				))}
			</Modal>
			<Modal
				title="Send Invitaion"
				open={showLinkedInInvitaion}
				onOk={(e) => {
					setShowLinkedInInvitaion(false);
				}}
				onCancel={(e) => {
					setShowLinkedInInvitaion(false);
				}}
			>
				{showError(
					globalSuccess.status,
					globalSuccess.message,
					globalError.status,
					globalError.message
				)}
				<Form
					form={form}
					name="linkedin_message"
					onFinish={sendInvitation}
					layout="vertical"
					style={{ maxWidth: "700px", margin: "auto" }}
				>
					<Form.Item
						name="displayName"
						label="LinkedIn Username"
						rules={[
							{ required: true, message: "Please enter the LinkedIn username" },
						]}
					>
						<Select
							mode="tags"
							style={{ width: "100%" }}
							placeholder="LinkedIn Users"
						>
							{selectedRows?.map((row) => (
								<Option key={row._id} value={row.candidate.first_name}>
									<Tag color="blue">{row.candidate.first_name}</Tag>
								</Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item>
						<Button
							type="primary"
							onClick={() => {
								insertVariable("name");
							}}
							style={{ marginLeft: "10px" }}
						>
							Name
						</Button>{" "}
						<Button
							type="primary"
							onClick={() => {
								insertVariable("email");
							}}
							style={{ marginLeft: "10px" }}
						>
							Email
						</Button>{" "}
						<Button
							type="primary"
							onClick={() => {
								insertVariable("phone");
							}}
							style={{ marginLeft: "10px" }}
						>
							Phone
						</Button>{" "}
						{/* <Button
                type="primary"
                onClick={() => {
                  insertVariable("value 1");
                }}
                style={{ marginLeft: "10px" }}
              >
                Value 1
              </Button> */}
					</Form.Item>

					<Form.Item
						name="message"
						label="Message"
						// rules={[{ required: true, message: 'Please enter your message' }]}
						rules={[
							{ required: true, message: "Please enter your message" },
							{
								validator: (_, value) =>
									value && value.length <= maxChars
										? Promise.resolve()
										: Promise.reject(
												new Error(
													`Message must be at most ${maxChars} characters`
												)
										  ),
							},
						]}
					>
						<TextArea
							rows={8}
							placeholder="Type your message here"
							ref={textAreaRef}
							value={textValue}
							onChange={(e) => setTextValue(e.target.value)}
						/>
					</Form.Item>

					<br />

					{/* <CkEditorFormItem
                          name="message"
                          label="Message"
                          rules={[{ required: true, message: 'Please enter your message' }]}
                          editorRef={editorRef}
                      /> */}

					<Form.Item>
						<Button type="primary" htmlType="submit">
							Send Message
						</Button>
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				title="Send Email"
				open={showEmailForm}
				onOk={(e) => {
					setShowEmailForm(false);
				}}
				onCancel={(e) => {
					// setShowEmailForm(false);
					handleCloseEmailModal();
				}}
			>
				<Form
					form={form}
					name="email_form"
					onFinish={sendEmail}
					onFinishFailed={onFinishFailed}
					initialValues={emailData}
					layout="vertical"
					style={{ maxWidth: "600px", margin: "auto" }}
				>
					<Form.Item
						name="to"
						label="To"
						rules={[{ required: true, message: "Please enter the email" }]}
					>
						<Select
							mode="tags"
							style={{ width: "100%" }}
							placeholder="Send Email To:"
						>
							{emailData.to.map((to, idx) => (
								<Option key={idx} value={to}>
									<Tag color="blue">{to}</Tag>
								</Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item
						label="Subject"
						name="subject"
						rules={[
							{ required: true, message: "Please enter the email subject" },
						]}
					>
						<Input placeholder="Email Subject" />
					</Form.Item>

					<Form.Item>
						<Button
							type="primary"
							onClick={() => {
								insertVariable("name");
							}}
							style={{ marginLeft: "10px" }}
						>
							Name
						</Button>{" "}
						<Button
							type="primary"
							onClick={() => {
								insertVariable("email");
							}}
							style={{ marginLeft: "10px" }}
						>
							Email
						</Button>{" "}
						<Button
							type="primary"
							onClick={() => {
								insertVariable("phone");
							}}
							style={{ marginLeft: "10px" }}
						>
							Phone
						</Button>{" "}
					</Form.Item>

					<Form.Item
						label="Message"
						name="message"
						rules={[{ required: true, message: "Please enter your message" }]}
					>
						<TextArea rows={6} placeholder="Type your message here" />
					</Form.Item>
					<Form.Item
						label="Pause Second"
						name="pause"
						rules={[
							{ required: true, message: "Please enter the pause second" },
						]}
						initialValue={2}
					>
						<Input type={"number"} defaultValue={2} min={2} />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							Send Email
						</Button>
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				title="Document Viewer"
				open={showCV}
				onOk={() => {
					setShowCv(false);
				}}
				onCancel={() => {
					setShowCv(false);
				}}
				style={{
					top: 0,
					right: 0,
					height: "100vh",
					position: "fixed",
					margin: 0,
					padding: 0,
				}}
				bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
				width={1000}
			>
				<PdfViewer fileUrl={cvPath} />
			</Modal>
			<Modal
				title={`Delete Candidate${idsToDelete.length > 1 ? "s" : ""}`}
				open={openDltModal}
				onOk={handleDeleteCandidate}
				okText="Delete"
				cancelText="Cancel"
				confirmLoading={isDeleting}
				onCancel={handleCancelDeleteModal}
			>
				<h5>
					Are you sure you want to delete{" "}
					{idsToDelete.length > 1 ? "these candidates" : "this candidate"}?
				</h5>
				<p style={{ color: "red" }}>This cannot be undone!</p>
			</Modal>
			 {selectedEmailHistoryRecord && selectedEmailHistoryRecord.length > 0 && (
  <Modal
    title="Email Conversation"
    visible={isEmailHistoryModalVisible}
    onCancel={handleModalClose}
    footer={null}
  >
    {selectedEmailHistoryRecord.map((record, index) => (
      <div key={index}>
        {/* Message Box */}
        <Card className="message-box" style={{ marginBottom: '20px', backgroundColor: '#f0f2f5' }}>
          <Typography.Text strong>Sent Message:</Typography.Text>
          <br />
          <Typography.Text>From: {record.from_email}</Typography.Text>
          <br />
          <Typography.Text>To: {record.to_email}</Typography.Text>
          <br />
          <Typography.Text>Subject: {record.subject}</Typography.Text>
          <br />
          <div
            dangerouslySetInnerHTML={{ __html: record.message }}
            style={{
              padding: '10px',
              whiteSpace: 'pre-wrap',
              borderRadius: '5px',
              backgroundColor: '#fff',
            }}
          />
          <Typography.Text type="secondary">{new Date(record.created_at).toLocaleString()}</Typography.Text>
        </Card>

        {/* Reply Box */}
        {record.is_replied && (
          <Card className="reply-box" style={{ backgroundColor: '#e6fffb' }}>
            <Typography.Text strong>Reply Message:</Typography.Text>
            <br />
            <Typography.Text>From: {record.to_email}</Typography.Text>
            <br />
            <div
              dangerouslySetInnerHTML={{ __html: record.reply_message }}
              style={{
                padding: '10px',
                whiteSpace: 'pre-wrap',
                borderRadius: '5px',
                backgroundColor: '#fff',
              }}
            />
            <br />
            <Typography.Text type="secondary">
              {new Date(record.meta.reply_receivedDateTime).toLocaleString()}
            </Typography.Text>
          </Card>
        )}
      </div>
    ))}
  </Modal>
)}

			<Notes
				handleCancel={() => setShowNotes(false)}
				isVisible={showNotes}
				handleNotes={handleNotes}
				notes={NotesData}
				jobOpenings={selectedRow}
				user={users}
				updateNoteState={setShowNotes}
				handleUpdateNote={handleUpdateNotes}
				handleDeleteNote={handleDeleteNotes}
				notesField={"multipleNotes"}
			/>

			<CustomSnackbar
				success={SendBulkEmail.success || SearchContact.success}
				error={SendBulkEmail.error || SearchContact.error}
			/>
			<section
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100dvh",
					width: "100%",
				}}
			>
				<Card
					style={{
						zIndex: 1,
					}}
				>
					<Row style={{}} align="middle" justify="space-between">
						<Col>
							<Input
								placeholder="Search"
								prefix={<SearchOutlined />}
								style={{ width: 200 }}
								onChange={handleSearch}
							/>
						</Col>
						<Col>
							<Space>
								<Typography.Text>
									Job ID :{" "}
									<Typography.Text strong copyable>
										{record?.opening_id}
									</Typography.Text>
								</Typography.Text>
								<Tooltip title="set page size">
									<InputNumber
										min={1}
										value={pageSize}
										onChange={(value) => setPageSize(value)}
										style={{ width: 100, textAlign: "center" }}
									/>
								</Tooltip>
								<Select
									placeholder="Filter Label"
									onChange={handleChangeLabel}
									optionLabelProp="label"
									value={selectedFilters}
									mode="multiple"
									style={{ minWidth: "200px" }}
								>
									<Select.Option value="top" label="Best">
										<div className="demo-option-label-item">
											Best Candidates
										</div>
									</Select.Option>
									<Select.Option value="good" label="Good">
										<div className="demo-option-label-item">
											Good Candidates
										</div>
									</Select.Option>
									<Select.Option value="qualified" label="Qualified">
										<div className="demo-option-label-item">
											Qualified Candidates
										</div>
									</Select.Option>
								</Select>
								<Button icon={<SortOutlined />}>Sort</Button>
								<NavLink to={`/${rolePath}/addCandidate`}>
									<Button type="primary" icon={<PlusOutlined />}>
										Add Candidate
									</Button>
								</NavLink>
								<Button
									onClick={() => setIsModalVisible(true)}
									icon={<ColumnHeightOutlined />}
								>
									Columns
								</Button>
								<GlobalSettings
									onClickSendEmail={() => {
										onClickSendEmail();
									}}
									onClickLinkedIn={handleLinkedInInviationModal}
									searchContact={searchContact}
								/>
							</Space>
						</Col>
					</Row>
				</Card>

				<br />

				<Card
					title={`Candidates List For ${record.opening_title}`}
					bordered={false}
					style={{
						flexGrow: 1,
						overflowY: listView ? "hidden" : "auto",
					}}
					extra={
						<Row
							style={{
								display: "flex",
								gap: "8px",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Button
								icon={<EmailOutlined />}
								onClick={() => onClickSendEmail()}
							>
								Send Email ({selectedCandidateIds?.length}){" "}
							</Button>

							{selectedRows?.length > 0 && listView && (
								<Button
									type="danger"
									icon={<DeleteOutlined />}
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
									onClick={() =>
										handleDeleteMultipleCandidates(selectedCandidateIds)
									}
								>
									Selected ({selectedRows?.length})
								</Button>
							)}
							{!listView && (
								<Row justify="space-between" span={24} align="middle">
									<Checkbox
										indeterminate={
											selectedCandidateIds.length > 0 &&
											selectedCandidateIds.length < candidateList.length
										}
										checked={
											selectedCandidateIds.length === candidateList.length
										}
										onChange={handleSelectAll}
									>
										{selectedCandidateIds.length === candidateList.length
											? "Unselect All"
											: "Select All"}
									</Checkbox>
									<Space>
										<Button
											type="danger"
											icon={<DeleteOutlined />}
											onClick={() =>
												handleDeleteMultipleCandidates(selectedCandidateIds)
											}
											disabled={selectedCandidateIds.length === 0}
										/>
									</Space>
								</Row>
							)}
							<span style={{ marginRight: "4px" }}>Change View</span>
							<Switch
								onChange={handleView}
								title="Change View Style"
								checkedChildren={<List />}
								unCheckedChildren={<TableOutlined />}
							/>
						</Row>
					}
					actions={
						!listView
							? [
									<Pagination
										total={totalData || 0}
										pageSize={pageSize}
										current={currentPage || 1}
										showSizeChanger={true}
										showQuickJumper={true}
										showTotal={(total, range) =>
											`${range[0]}-${range[1]} of ${total} items`
										}
										onChange={(page, size) => {
											dispatch(setPage(page));
											dispatch(setPageSize(size));
											dispatch(
												fetchCandidates({
													jobId: record.opening_id,
													page,
													pageSize: size,
													search,
												})
											);
										}}
									/>,
							  ]
							: null
					}
				>
					{listView ? (
						// <div style={{ overflow: "scroll" }}>
						<Table
							loading={loading}
							dataSource={filteredCandidates}
							rowKey={(record) => record.candidate._id}
							onChange={handleTableChange}
							pagination={{
								total: totalData || 0,
								pageSize: pageSize,
								current: currentPage || 1,
								showSizeChanger: true,
								onChange: (page, size) => {
									dispatch(setPage(page));
									dispatch(setPageSize(size));
									dispatch(
										fetchCandidates({
											jobId: record.opening_id,
											page,
											pageSize: size,
											search,
										})
									);
								},
							}}
							rowSelection={{
								type: selectionType,
								selectedRowKeys: selectedCandidateIds,
								onChange: (selectedRowKeys, selectedRows) => {
									dispatch(setSelectedCandidateIds(selectedRowKeys));
									setSelectedRows(selectedRows);
								},
							}}
							columns={columns.filter((column) => column.show)}
							// style={
							// 	{
							// 		// height: "500px",
							// 		// overflowY: "scroll",
							// 		// overflowX: "scroll",
							// 	}
							// }
							// scroll={{ x: "600px", y: "calc(100vh - 300px)" }}
							// style={{
							// 	width: "85vw",
							// }}
							scroll={{
								x: 1500,
								y: 200,
							}}
							// style={{
							// 	overflowY: "scroll",
							// }}
						/>
					) : (
						// </div>
						<>
							{/* <div style={{ display: 'flex' }}> */}

							{filteredCandidates &&
								filteredCandidates.map((cndidate, index) => {
									const { candidate, employment, qualifications,emailHistory } = cndidate;

									const candidateLabel = bestCandidates.includes(candidate._id)
										? "top"
										: goodCandidates.includes(candidate._id)
										? "good"
										: qualifiedCandidates.includes(candidate._id)
										? "qualified"
										: "";
									return (
										<Card
											key={candidate._id + index}
											style={{ maxWidth: "100%" }}
										>
											<Row>
												<Col
													span={24}
													style={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
													}}
												>
													<Col
														style={{
															display: "flex",
															gap: "16px",
															justifyContent: "space-between",
															alignItems: "center",
														}}
													>
														<Checkbox
															onChange={() =>
																handleCheckboxChange(candidate._id)
															}
															checked={selectedCandidateIds.includes(
																candidate._id
															)}
														/>
														<Avatar
															style={{
																backgroundColor: "#ed7206",
																marginRight: "8px",
																display: "flex",
																justifyContent: "center",
																alignItems: "center",
															}}
															size={{
																xs: 24,
																sm: 32,
																md: 40,
																lg: 54,
																xl: 60,
																xxl: 100,
															}}
															icon={
																candidate.profile_image ? (
																	<Image
																		width={120}
																		preview={false}
																		src={
																			IMAGE_CANDIDATE_URL +
																			candidate.profile_image
																		}
																	/>
																) : (
																	<Typography.Title className="mb-0 text-white">
																		{candidate.first_name &&
																		candidate.first_name !== ""
																			? candidate.first_name
																					.charAt(0)
																					.toUpperCase()
																			: ""}
																	</Typography.Title>
																)
															}
														></Avatar>
														<Col>
															<Space
																style={{ display: "flex" }}
																direction="horizontal"
																size="middle"
															>
																<NavLink
																	to={{
																		pathname: `/${type}/candidateProfileDetail`,
																		state: {
																			item: {
																				...candidate,
																				opening_id: record.opening_id,
																			},
																		},
																	}}
																>
																	<Typography.Link
																		style={{
																			margin: 0,
																			fontSize: "16px",
																			fontWeight: "bold",
																		}}
																	>
																		{candidate.first_name} {candidate.last_name}
																	</Typography.Link>
																</NavLink>{" "}
																<Button
																	onClick={() => {
																		// console.log("record getNotes->", record);
																		getNotes(cndidate);
																	}}
																	// icon={

																	//   <Note />
																	// }

																	title="Notes"
																>
																	N
																</Button>
															</Space>
															<Typography.Text
																className="mb-5"
																type="secondary"
															>
																{employment[0]?.is_current_company
																	? employment[0].designation +
																	  " at " +
																	  employment[0].organization
																	: "-"}
															</Typography.Text>
															<Typography.Text
																type="secondary"
																style={{
																	display: "block",
																	marginBottom: "8px",
																}}
															>
																{candidate.current_location}
															</Typography.Text>
															{/* <Space size={8}>
																<Row>
																	{candidate?.tags?.map((tag) => (
																		<Tag key={tag} color="blue">
																			{tag}
																		</Tag>
																	))}
																</Row>
															</Space>
															<div>
																<Space  span="4" style={{display:'flex' ,gap:'2px'}}>
																	{candidate?.meta?.linkedin && (
																		<Typography.Link>
																			<LinkedinOutlined />
																			<a
																				href={candidate?.meta?.linkedin}
																				target="_blank"
																				rel="noreferrer"
																			>
																				<LinkOutlined />
																			</a>
																		</Typography.Link>
																	)}
																	{candidate?.meta?.Indeed && (
																		<Typography.Link>
																			<GlobalOutlined />
																			<a
																				href={candidate?.meta?.Indeed}
																				target="_blank"
																				rel="noreferrer"
																			>
																				<LinkOutlined />
																			</a>
																		</Typography.Link>
																	)}
																</Space>
																
																	
																<Col span={24}>
      <LinkedInInvitationStatus candidate={candidate} />
    </Col>
																
															</div> */}
															<div>
																<Col span={24}>
																	<Space size={8} wrap>
																		{candidate?.tags?.map((tag) => (
																			<Tag key={tag} color="blue">
																				{tag}
																			</Tag>
																		))}
																	</Space>
																</Col>

																<Row className="p-1" align="middle">
																	<Col span={24}>
																		<Space direction="vertical" size={4}>
																			<Space size={12}>
																				{candidate?.meta?.linkedin && (
																					<Typography.Link
																						href={candidate.meta.linkedin}
																						target="_blank"
																						rel="noreferrer"
																					>
																						<LinkedinOutlined
																							style={{
																								fontSize: "20px",
																								color: "#0077B5",
																							}}
																						/>
																					</Typography.Link>
																				)}
																				{candidate?.meta?.Indeed && (
																					<Typography.Link
																						href={candidate.meta.Indeed}
																						target="_blank"
																						rel="noreferrer"
																					>
																						<GlobalOutlined
																							style={{
																								fontSize: "20px",
																								color: "#2164f4",
																							}}
																						/>
																					</Typography.Link>
																				)}
																			</Space>
																		</Space>
																	</Col>
																	<Col span={24}>
																		<EmailStatus
																			emailHistory={emailHistory}
																		/>
																	</Col>
																	<Col span={24}>
																		<LinkedInInvitationStatus
																			candidate={candidate}
																		/>
																	</Col>
																</Row>
															</div>
															<Col
																style={{
																	display: "flex",
																	justifyContent: "flex-start",
																	alignItems: "center",
																	gap: "8px",
																}}
															>
																{isValidEmails(candidate.email) ? (
																	<div style={{ marginTop: "2px" }}>
																		{candidate.email
																			.split(",")
																			.map((email, index) => (
																				<div
																					key={index}
																					style={{
																						display: "flex",
																						gap: "2px",
																					}}
																				>
																					<Email></Email>
																					<p>{email.trim()}</p>
																				</div>
																			))}
																	</div>
																) : null}{" "}
																{isValidEmails(candidate.email) ? (
																	<Button
																		title="Send Email"
																		onClick={() => onClickSendEmail(cndidate)}
																		icon={<SendOutlined />}
																	/>
																) : null}
															</Col>
															<Space direction="vertical" size="middle">
																<Space direction="horizontal">
																	<Radio.Group
																		value={candidateLabel}
																		onChange={(e) =>
																			handleLabelChange(
																				candidate._id,
																				e.target.value
																			)
																		}
																		size="small"
																	>
																		<Radio.Button value="top">
																			Best
																		</Radio.Button>
																		<Radio.Button value="good">
																			Good
																		</Radio.Button>
																		<Radio.Button value="qualified">
																			Qualified
																		</Radio.Button>
																	</Radio.Group>
																</Space>
															</Space>
														</Col>
													</Col>
													{/* actions */}
													<Col
														style={{
															display: "flex",
															flexDirection: "column",
															gap: "4px",
														}}
													>
														<Space size="middle">
															<NavLink
																to={{
																	pathname: `/${rolePath}/edit-candidate`,
																	state: {
																		item: {
																			...candidate,
																			opening_id: record.opening_id,
																		},
																	},
																}}
															>
																<Button
																	type="primary"
																	icon={<EditOutlined />}
																/>
															</NavLink>
															<NavLink
																to={{
																	pathname: `/${type}/candidateProfileDetail`,
																	state: {
																		item: {
																			...candidate,
																			opening_id: record.opening_id,
																		},
																	},
																}}
															>
																<Button type="primary" icon={<EyeOutlined />} />
															</NavLink>
															<Button
																type="danger"
																icon={<DeleteOutlined />}
																onClick={() => openDeleteModal(candidate._id)}
															></Button>
														</Space>
													</Col>
												</Col>
											</Row>
											<Divider></Divider>
											<Row>
												<Col span={2}>
													<Typography.Text>Experience</Typography.Text>
												</Col>

												<Col
													span="4"
													className=""
													style={{ display: "flex", alignItems: "center" }}
												>
													<BusinessCenterIcon
														style={{ color: "#000", marginRight: "3px" }}
													/>
													<Typography.Text>
														{(candidate.total_work_exp_year ?? 0) +
															" Year(s) " +
															(candidate.total_work_exp_month ?? 0) +
															" Month(s)"}
													</Typography.Text>
												</Col>
												<Divider type="vertical" style={{ height: "auto" }} />
											</Row>

											<Row justify="start">
												<Col span={2}>
													<Typography.Text>Skills</Typography.Text>
												</Col>
												<Col span={8} className="">
													<ExpandableContent
														content={
															<div
																style={{
																	display: "flex",
																	flexWrap: "wrap",
																	gap: "4px",
																}}
															>
																{candidate?.key_skills?.map((skill, index) => (
																	<Typography.Text
																		key={skill + index}
																		style={{
																			whiteSpace: "nowrap",
																			marginRight: "8px",
																		}}
																	>
																		{skill}
																	</Typography.Text>
																))}
															</div>
														}
														maxHeight={48}
														showMoreText="Show more skills"
														showLessText="Show fewer skills"
													/>
												</Col>
												<Col span={14}>
													<Tabs defaultActiveKey="1" tabPosition="left">
														<Tabs.TabPane tab="Qualification" key="1">
															<Col span={12} className="">
																<ExpandableContent
																	content={
																		<Timeline className="p-1">
																			{employment.map((job, index) => (
																				<Timeline.Item key={index}>
																					<strong>{job.designation}</strong> at{" "}
																					<strong>{job.organization}</strong>
																					<br />
																					{job.work_since_from_year} -{" "}
																					{job.is_current_company
																						? "Present"
																						: job.work_since_to_year || "N/A"}
																					<br />
																					{job.location}
																				</Timeline.Item>
																			))}
																		</Timeline>
																	}
																	maxHeight={58}
																	showMoreText="Show more"
																	showLessText="Show fewer"
																/>
															</Col>
														</Tabs.TabPane>
														<Tabs.TabPane tab="Employment" key="2">
															<Col span={12} className="">
																<ExpandableContent
																	content={
																		<Timeline className="p-1">
																			{qualifications.map((qual, index) => (
																				<Timeline.Item
																					key={`qualification-${index}`}
																				>
																					<strong>{qual.course}</strong>
																					{qual.university && (
																						<span>
																							{" "}
																							from{" "}
																							<strong>{qual.university}</strong>
																						</span>
																					)}
																					<br />
																					{qual.passing_year && (
																						<span>({qual.passing_year})</span>
																					)}
																				</Timeline.Item>
																			))}
																		</Timeline>
																	}
																	maxHeight={58}
																	showMoreText="Show more"
																	showLessText="Show fewer"
																/>
															</Col>
														</Tabs.TabPane>
													</Tabs>
												</Col>
											</Row>
											<Row>
												<Col span={2}>
													<Typography.Text>Summary</Typography.Text>
												</Col>
												<Col span={16}>
													<ExpandableContent
														content={
															candidate.profile_summary ? (
																<Typography.Paragraph>
																	{candidate.profile_summary}
																</Typography.Paragraph>
															) : null
														}
														maxHeight={22}
														showMoreText="Read more"
														showLessText="Read less"
													/>
												</Col>
											</Row>
										</Card>
									);
								})}
						</>
					)}
				</Card>
			</section>
		</>
	);
}
