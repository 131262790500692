import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
	Button,
	Card,
	Col,
	Form,
	Input,
	Row,
	Select,
	Tag,
	Typography,
	Upload,
} from "antd";
import { setRole, setToken, setUser } from "../../../redux/actions/users";
import { getBase64, showError } from "../../pages/utils/helpers";
import { CANDIDATE, CANDIDATE_UPDATE } from "../../../ApiUrl";
import {
	monthsOfExpirance,
	yearsOfExpirance,
} from "../../pages/constant/constant";
import { requestHeader } from "../../../others/header";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import { logoutHandler } from "../../../others/logout";

const { Option } = Select;
const { Text } = Typography;
const FormItem = Form.Item;

function EditCandidate(props) {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [msgSuccess, setMsgSuccess] = useState("");
	const [msgError, setmsgError] = useState("");
	const [selectedFileList, setSelectedFileList] = useState([]);
	const [selectedFile, setSelectedFile] = useState("");
	const [fileError, setFileError] = useState(false);
	const history = useHistory();

	const [tags, setTags] = useState([]);

	const handleChange = (value) => {
		setTags(value);
	};

	const propsUpload = {
		onRemove: (file) => {
			const index = selectedFileList.indexOf(file);
			const newFileList = selectedFileList.slice();
			newFileList.splice(index, 1);
			setSelectedFileList(newFileList);
		},
		beforeUpload: (file) => {
			if (file.size > 5000000) {
				setError(true);
				setmsgError("File should be less than 5 MB.");
				// setDefaultState();
				setDefaultStateForFile();
				return false;
			}

			if (
				file.type !==
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
				file.type !== "application/msword" &&
				file.type !== "application/pdf" &&
				file.type !== "text/csv"
			) {
				setError(true);
				setmsgError("Not a supported file format.");
				// setDefaultState();
				setDefaultStateForFile();
				return false;
			} else {
				setSelectedFileList([...selectedFileList, file]);
				let idCardBase64 = ""; //vnd.openxmlformats-officedocument.wordprocessingml.document
				//msword
				getBase64(file, (result) => {
					idCardBase64 = result.replace(
						"vnd.openxmlformats-officedocument.wordprocessingml.document",
						"msword"
					);
					setSelectedFile(idCardBase64);
					const values = {
						attachments: idCardBase64,
						// profile_summary: userProfileDetails.profile_summary,
					};
					postEditSummary(values);
				});
				setFileError(false);
			}
			return false;
		},
		selectedFileList,
	};

	let location = useLocation();
	let { _id } = location.state.item;
	// console.log(location.state.item, "location state in edit component");

	const getCandidateDetail = (candidateId) => {
		axios
			.get(`${CANDIDATE}/${candidateId}`, requestHeader())
			.then((res) => {
				if (res.data.statusCode == 200) {
					form.current.setFieldsValue(res.data.data[0]);

					setSelectedFileList(res.data.data[0].attachments);
				}
			})
			.catch((error) => {
				if (error?.response?.status == 401) {
					logoutHandler(history);
				}
			});
	};

	const updateCandidate = () => {
		setLoading(true);
		axios
			.put(
				`${CANDIDATE_UPDATE}/${_id}`,
				{ ...form.current.getFieldsValue(), attachments: selectedFile },
				requestHeader()
			)
			.then((response) => {
				setLoading(false);
				setSuccess(true);
				setMsgSuccess(response?.data?.message);
			})
			.catch((error) => {
				setError(true);
				setLoading(false);
				setmsgError(error?.data?.message);
				if (error?.response?.status == 401) {
					logoutHandler(history);
				}
			})
			.finally((f) => {
				setTimeout(() => {
					setSuccess(false);
					setLoading(false);
					setError(false);
					setMsgSuccess("");
					setmsgError("");
				}, 3000);
			});
	};

	useEffect(() => {
		if (_id) {
			getCandidateDetail(_id);
		}
	}, []);

	return (
		<Form
			ref={form}
			id="AddCandidate"
			onFinish={updateCandidate}
			layout="vertical"
		>
			{showError(success, msgSuccess, error, msgError)}

			<Card
				title={"Edit Candidate"}
				// extra={
				//   <NavLink
				//     className="pull-right w-100 text-right"
				//     to={
				//       {
				//         // pathname: "/company/job-openings",
				//       }
				//     }
				//   >
				//     <Button
				//       onClick={() => {
				//         props.history.goBack();
				//       }}
				//       type="Secondary"
				//     >
				//       Back
				//     </Button>
				//   </NavLink>
				// }
			>
				<Row gutter={24}>
					<Col span={8}>
						<FormItem
							label="First name"
							name={"first_name"}
							rules={[
								{
									required: true,
									message: "First name is required.",
								},
							]}
						>
							<Input placeholder="First name" value={"tarek"} />
						</FormItem>
					</Col>
					<Col span={8}>
						<FormItem label="Middle name" name={"middle_name"}>
							<Input placeholder="Middle name" />
						</FormItem>
					</Col>
					<Col span={8}>
						<FormItem
							label="Last name"
							name={"last_name"}
							rules={[
								{
									required: true,
									message: "Last name is required.",
								},
							]}
						>
							<Input placeholder="Last name" />
						</FormItem>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col span={12}>
						<FormItem
							label="Email"
							name={"email"}
							rules={[
								{
									type: "email",
									message: "The input is not valid E-mail!",
								},
								{
									required: true,
									message: "Email Address is required.",
								},
							]}
						>
							<Input placeholder="Email" />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem
							label="Mobile"
							name={"mobile"}
							rules={[
								{
									required: true,
									message: "Mobile is required.",
								},
							]}
						>
							<Input placeholder="Mobile" />
						</FormItem>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col span={24}>
						<FormItem label="Total Experience">
							<Row gutter={24}>
								<Col span={12}>
									<FormItem label="Years" name={"total_work_exp_year"}>
										<Select>
											{yearsOfExpirance.map((user, index) => {
												return (
													<Option value={user.value} key={index}>
														{user.label + (index < 2 ? " Year" : " Years")}
													</Option>
												);
											})}
										</Select>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem label="Months" name={"total_work_exp_month"}>
										<Select>
											{monthsOfExpirance.map((user, index) => {
												return (
													<Option value={user.value} key={index}>
														{user.label + (index < 2 ? " Month" : " Months")}
													</Option>
												);
											})}
										</Select>
									</FormItem>
								</Col>

								<Col span={12}>
									<Form.Item label="Input tags" name={"tags"}>
										<Select
											mode="tags"
											style={{ width: "100%" }}
											placeholder="input tags"
											onChange={handleChange}
											value={tags}
										>
											{tags.map((tag) => (
												<Option key={tag} value={tag}>
													<Tag color="blue">{tag}</Tag>
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem label="Notes" name={"notes"}>
							<Input.TextArea rows={4} placeholder="Notes" />
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label={
								<span>
									<span style={{ color: "#ff4d4f" }}>*</span> Upload Document
									(only doc, docx, pdf, csv)
								</span>
							}
							name={"attachments"}
						>
							<Upload {...propsUpload} accept=".pdf, .doc, .docx , .csv">
								<Button>UPLOAD RESUME</Button>
							</Upload>
							<Text type="secondary">
								Supported Formats: doc, docx, pdf, csv, upto 5 MB
							</Text>
							{fileError && (
								<p style={{ color: "#ff4d4f" }}>Please upload a document</p>
							)}
						</FormItem>
					</Col>
				</Row>
			</Card>
			<br />
			<Card>
				<Row gutter={24}>
					<Col span={24}>
						{loading ? (
							<Button type="primary" size="large" loading>
								Saving . .
							</Button>
						) : (
							<Button type="primary" size="large" htmlType="submit">
								Update
							</Button>
						)}
					</Col>
				</Row>
			</Card>
		</Form>
	);
}

const mapDispatchToProps = (dispatch) => ({
	setRole: (data) => {
		dispatch(setRole(data));
	},
	setUser: (data) => {
		dispatch(setUser(data));
	},
	setToken: (data) => {
		dispatch(setToken(data));
	},
});

export default connect(null, mapDispatchToProps)(EditCandidate);
