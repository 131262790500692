import {
  InboxOutlined,
  MoreOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import {
  Close,
  Delete,
  Description,
  Edit,
  Headset,
  MoreVert,
  Phone,
} from "@material-ui/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Modal,
  Radio,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import Search from "antd/lib/transfer/search";
import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../../CSS/ckeditor.css";
import { CustomSnackbar } from "../others/Snackbar";
import { getFormatDate } from "../../pages/utils";
import { useCallAPI } from "../../../hooks/useCallAPI";
import {
  CANDIDATE_NOTE_DELETE,
  DELETE_JOB_OPENINGS_NOTE,
  UPDATE_JOB_OPENINGS_NOTE,
} from "../../../ApiUrl";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

const FormItem = Form.Item;
const { Dragger } = Upload;

const GRAY = { color: "gray" };

export default function Notes({
  handleNotes,
  handleCancel,
  isVisible,
  notes,
  jobOpenings,
  user,
  updateNoteState,
  handleUpdateNote,
  handleDeleteNote, notesField = "notes"
}) {
  const [form] = Form.useForm();
  const [activeEditor, setActiveEditor] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [type, setType] = useState("");
  const [isUpdate, setIsUpdate] = useState(null);

  const DeleteNotes = useCallAPI(DELETE_JOB_OPENINGS_NOTE);
  const UpdateNotes = useCallAPI(UPDATE_JOB_OPENINGS_NOTE);

  const handleDeleteNotes = (noteId) => {
    // const data = notes?.data?.data?.notes || notes?.data?.data?.multipleNotes
    // let deletedNoteIndex = notes?.data?.data[notesField].findIndex(
    //   (note) => note._id == noteId
    // );

    // delete data.notes[deletedNoteIndex];
    if (handleDeleteNote) {
      handleDeleteNote(noteId);
      handleCancel();
      return
    }
    DeleteNotes.deletee(`${jobOpenings?._id}/${noteId}`);
    handleCancel();
  };

  const handleActiveEditor = (value, showEditor = false) => {
    form.current.resetFields();
    setEditorContent("");
    if (value) {
      setType(value);
      form.current.setFieldsValue({ type: value });
    }
    setActiveEditor(showEditor);
    setType(undefined)
  };
const filteredNotes = notes?.data?.data[notesField]?.filter((note) =>
    type ? note.type === type : true
  );
  const Type = () => {
    return (
      <Select
          value={type}
        onChange={(value) => {
          setType(value);
        }}
        placeholder="Select Types"
        style={{ width: "200px" }}
      // value={"call"}
      >
        {["Call", "Meeting", "Notes", "Others"].map((option, idx) => (
          <Select.Option value={option.toLowerCase()} key={idx}>
            {option}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const updateNotes = (value, id) => {
    if (handleUpdateNote) {
      handleUpdateNote(value, id);
      return;
    }
    UpdateNotes.patch(value, `${jobOpenings?._id}/${id}`);
  };

  const handleEdit = (id) => {
    const selectedNotes = notes?.data?.data[notesField]?.filter(
      (note) => note._id == id
    );

    form.current.setFieldsValue(selectedNotes[0]);
    setEditorContent(selectedNotes[0].notes);
    setType(selectedNotes[0].type);
    setActiveEditor(true);
    setIsUpdate(selectedNotes[0]?._id);
  };

  const onFinish = (value) => {
    const updatedValue = { ...value, notes: editorContent };
    if (isUpdate) {
      updateNotes(updatedValue, isUpdate);
    } else {
      handleNotes(updatedValue);
    }

    handleCancel();
    handleActiveEditor();
  };

  return (
    <Modal
      title="Notes"
      open={isVisible}
      onCancel={() => {
        handleCancel();
        handleActiveEditor();
      }}
      footer={false}
      width={1000}
      style={{
        height: "100vh",
        margin: "auto",
        padding: 0,
      }}
      bodyStyle={{ height: "calc(100% - 55px)", width: "1000px" }}
    >
      <CustomSnackbar
        success={notes.success || DeleteNotes.success || UpdateNotes.success || notes.message}
        error={notes.error || DeleteNotes.error || UpdateNotes.error}
      />

      <div style={{ display: !activeEditor ? "block" : "none" }}>
        <Row style={{ width: "100%" }} align="middle">
          {/*<Col style={{ width: "250px", marginRight: "15px" }}>*/}
          {/*  <Search*/}
          {/*    placeholder="Search..."*/}
          {/*    // onChange={(e) => handleSearch(e, timeoutRef, setSearch)}*/}
          {/*    style={{ width: 100 }}*/}
          {/*  />*/}
          {/*</Col>*/}

          <Col>{Type()}</Col>
        </Row>
        <br />
        <h5>Create Notes</h5>

        <Row style={{ width: "100%" }} align="middle">
          <Col style={{ margin: "0 5px" }}>
            <Button onClick={() => handleActiveEditor("call", true)}>
              <Phone />
            </Button>
          </Col>

          <Col style={{ margin: "0 5px" }}>
            <Button onClick={() => handleActiveEditor("meeting", true)}>
              <Headset />
            </Button>
          </Col>

          <Col style={{ margin: "0 5px" }}>
            <Button onClick={() => handleActiveEditor("notes", true)}>
              <Description />
            </Button>
          </Col>

          <Col style={{ margin: "0 5px" }}>
            <Button onClick={() => handleActiveEditor("others", true)}>
              <MoreVert />
            </Button>
          </Col>
        </Row>

        <br />

        {filteredNotes?.map((note) => (
          <Card
            style={{
              border: "1px solid #d9d9d9",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
            className="mb-5"
          >
            <Row justify="space-between" align="middle">
              <Col>
                <Button shape="circle" className="mr-2">
                  <Description />
                </Button>
                <span>{note?.type}</span>
              </Col>
              <Col>
                <Button
                  shape="round"
                  className="mr-2"
                  onClick={() => {
                    handleEdit(note?._id);
                  }}
                >
                  <Edit />
                </Button>

                <Button
                  shape="round"
                  onClick={() => {
                    handleDeleteNotes(note?._id);
                  }}
                >
                  <Close />
                </Button>
              </Col>
            </Row>
            <br />
            <Typography
              dangerouslySetInnerHTML={{ __html: note?.notes || "" }}
            ></Typography>

            <br />

            {jobOpenings?.opening_title && (<Typography>
              <NavLink
                to={{
                  pathname: `/${user.role}/opening-detail`,
                  state: { record: jobOpenings },
                }}
              >
                {/* <Button type="primary" icon={<EyeOutlined />} />
                 */}
                (
                <span style={{ color: "green" }}>
                  {jobOpenings?.opening_title}
                </span>
                )
              </NavLink>
            </Typography>)}
            <br />
            <Typography>
              <span style={GRAY}>Created By:</span>{" "}
              {note?.created_by?.display_name}
              {/* <span style={GRAY}>on</span> 06 Jan, 2024{" "} */}
              <span style={GRAY}>at</span> {getFormatDate(note?.created_at)}
            </Typography>
          </Card>
        ))}

        {!filteredNotes?.length && (
          <Typography style={GRAY}>Notes not found!</Typography>
        )}
      </div>

      <div
        style={{ display: activeEditor ? "block" : "none", overflow: "hidden" }}
      >
        <div>
          <Row justify="end">
            <Col>
              <Button
                onClick={() => {
                  handleActiveEditor();
                }}
              >
                <RollbackOutlined />
              </Button>
            </Col>
          </Row>
        </div>

        <Form
          ref={form}
          id="AddCandidate"
          onFinish={onFinish}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col>
              <FormItem label="Type" name={"type"}>
                {/* {Type()} */}
                <Select
                  onChange={(value) => {
                    // console.log(value);
                    // setType(value);
                  }}
                  placeholder="Select Types"
                  style={{ width: "200px" }}
                // value={"call"}
                >
                  {["All", "Call", "Meeting", "Notes", "Others"].map(
                    (option, idx) => (
                      <Select.Option value={option.toLowerCase()} key={idx}>
                        {option}
                      </Select.Option>
                    )
                  )}
                </Select>
              </FormItem>
            </Col>

            {type == "call" && (
              <Col>
                <div style={{ height: "27px" }}></div>
                <FormItem name="is_answered">
                  <Radio.Group
                  // onChange={onGenderChange}
                  // value={gender}
                  >
                    <Radio value={true}>Answered</Radio>
                    <Radio value={false}>Unanswered</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
            )}
          </Row>

          <Row>
            <FormItem label="Notes" name={"notes"}>
              <CKEditor
                editor={ClassicEditor}
                data={editorContent}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorContent(data);
                }}
              />
            </FormItem>
          </Row>

          {/*<Row gutter={16}>*/}
          {/*  <Col span={16}>*/}
          {/*    <Form.Item label="Upload Attachments">*/}
          {/*      <Dragger*/}
          {/*        // fileList={fileList}*/}
          {/*        // onChange={handleUploadChange}*/}
          {/*        beforeUpload={() => false} // Prevent automatic upload*/}
          {/*        multiple*/}
          {/*      >*/}
          {/*        <p className="ant-upload-drag-icon">*/}
          {/*          <InboxOutlined />*/}
          {/*        </p>*/}
          {/*        <p className="ant-upload-text">*/}
          {/*          Click or drag file to this area to upload*/}
          {/*        </p>*/}
          {/*        <p className="ant-upload-hint">*/}
          {/*          Support for a single or bulk upload. Strictly prohibit from*/}
          {/*          uploading company data or other band files.*/}
          {/*        </p>*/}
          {/*      </Dragger>*/}
          {/*    </Form.Item>*/}
          {/*  </Col>*/}
          {/*</Row>*/}

          <Row gutter={24}>
            <Col span={24}>
              <Button type="primary" size="large" htmlType="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}
