import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";

export const GlobalSettings = ({ onClickLinkedIn, onClickSendEmail, searchContact }) => {
  const menu = (
    <Menu>
      {/* <Menu.Item key="1">
        <Button>Settings</Button>
      </Menu.Item> */}
      <Menu.Item key="2">
        <Button onClick={onClickLinkedIn}>LinkedIn Invitation</Button>
      </Menu.Item>
      <Menu.Item key="3">
        <Button onClick={onClickSendEmail}>Send Email</Button>
      </Menu.Item>
      <Menu.Item key="3">
        <Button onClick={searchContact}>Search Contact</Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["hover"]}>
      <MoreOutlined style={{ fontSize: "24px", cursor: "pointer" }} />
    </Dropdown>
  );
};
